import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { VALIDATION } from '../../../const/text';
import Group from '../../../components/ui/FormEditView/Group';
import { INPUT_MAX_LENGTH, INPUT_MIN_LENGTH } from "../../../const/view";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputFetchResult from "../../../components/ui/InputFetchResultNew";
import {getDeliveriesSettlements} from "../../../lib/api/delivery";
import Block from "../../../components/ui/Block/Block";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import ColLeft from "../../../components/ui/FormEditView/ColLeft";
import ColRight from "../../../components/ui/FormEditView/ColRight";

const TabInfoUser = ({ index = 0, formProps }) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    const [dateOfBirth, setDateOfBirth] = useState(null)

    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    useEffect(() => {
        if (dataFields?.date_of_birth) {
            setDateOfBirth(dataFields?.date_of_birth)
        }
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const email = form.watch('email');
    const password = form.watch('password');

    useEffect(() => {
        if(email || password) {
            document.getElementById('pb2b-email')?.removeAttribute('readonly');
            document.getElementById('pb2b-password')?.removeAttribute('readonly');
        }
    }, [email, password]);

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide" />
            <Block title="Інформація користувача" style={{ width: '700px' }}>
                {/* surname */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputWrapper
                            label="Прізвище"
                            id="user-surname"
                            errorComponent={form?.errors?.surname &&
                            <p className="form-input-hint">{form?.errors?.surname?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: form?.errors?.surname,
                                })}
                                type="text"
                                placeholder="Прізвище"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register({
                                    required: VALIDATION.req.required
                                })}
                                name="surname"
                                id="user-surname"
                                defaultValue={dataFields?.surname}
                            />
                        </InputWrapper>
                    </ColFull>
                </Group>

                {/* name */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputWrapper
                            label="Ім'я"
                            id="user-name"
                            errorComponent={form?.errors?.name &&
                            <p className="form-input-hint">{form?.errors?.name?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: form?.errors?.name,
                                })}
                                type="text"
                                placeholder="Ім'я"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register({
                                    required: VALIDATION.req.required
                                })}
                                name="name"
                                id="user-name"
                                defaultValue={dataFields?.name}
                            />
                        </InputWrapper>
                    </ColFull>
                </Group>

                {/* patronymic */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputWrapper label="По-батькові" id="user-patronymic">
                            <input
                                className={s_Input.input}
                                type="text"
                                placeholder="По-батькові"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register()}
                                name="patronymic"
                                id="user-patronymic"
                                defaultValue={dataFields?.patronymic}
                            />
                        </InputWrapper>
                    </ColFull>
                </Group>

                {/* email */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputWrapper
                            label="Email"
                            id="user-email"
                            errorComponent={form?.errors?.email &&
                            <p className="form-input-hint">{form?.errors?.email?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: form?.errors?.email,
                                })}
                                type="email"
                                placeholder="Email"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register({
                                    required: VALIDATION.req.required
                                })}
                                name="email"
                                id="user-email"
                                defaultValue={dataFields?.email}
                            />
                        </InputWrapper>
                    </ColFull>
                </Group>

                {/* phone */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputWrapper
                            label="Телефон"
                            id="user-phone"
                            errorComponent={form?.errors?.phone &&
                            <p className="form-input-hint">{form?.errors?.phone?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: form?.errors?.phone,
                                })}
                                type="text"
                                placeholder="Телефон"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register({
                                    required: VALIDATION.req.required
                                })}
                                name="phone"
                                id="user-phone"
                                defaultValue={dataFields?.phone}
                            />
                        </InputWrapper>
                    </ColFull>
                </Group>

                {/* settlement_id */}
                <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputFetchResult
                            name="settlement_id"
                            form={form}
                            searchParam="title"
                            func={getDeliveriesSettlements}
                            defVal={dataFields?.settlement}
                            id="pb2b-settlement-id"
                            placeholder="Область/Населений пункт"
                        />
                    </ColFull>
                </Group>

                {/* password */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <InputWrapper
                            label="Пароль"
                            id="user-password"
                        >
                            <input
                                className={s_Input.input}
                                type="text"
                                placeholder="Пароль"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register()}
                                name="password"
                                id="user-password"
                                defaultValue={dataFields?.password}
                            />
                        </InputWrapper>
                    </ColFull>
                </Group>

                {/* date_of_birth */}
                <Group>
                    <ColLeft col={6}>
                        <div className={s_Input.wrapper}>
                            <div className={s_Input.input__wrapper}>
                                <input
                                    className={s_Input.input}
                                    type="date"
                                    name="date_of_birth"
                                    id="user-date-of-birth"
                                    placeholder="Дата народження"
                                    ref={form.register}
                                    value={dateOfBirth}
                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                />
                                <label className={s_Input.label} htmlFor="user-date-of-birth">Дата народження</label>
                            </div>
                        </div>
                    </ColLeft>
                    <ColRight col={6}>
                        <div className={s_Input.wrapper}>
                            <div className={s_Input.input__wrapper}>
                                <input
                                    className={s_Input.input}
                                    type="date"
                                    name="date_of_birth_changed_at"
                                    id="user-date-of-birth-changed-at"
                                    placeholder="Дата зміни"
                                    ref={form.register}
                                    value={dataFields?.date_of_birth_changed_at ? dataFields?.date_of_birth_changed_at?.split(' ')[0] : ''}
                                    readOnly
                                />
                                <label className={s_Input.label} htmlFor="user-date-of-birth-changed-at">Дата зміни</label>
                            </div>
                        </div>
                    </ColRight>
                </Group>

                {/* status */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                <select
                                    name="status"
                                    className={cn(s_Select.select, {
                                        [s_Select.select_error]: form.errors?.status,
                                    })}
                                    id="user-status"
                                    ref={form.register({
                                        required: VALIDATION.select.required,
                                    })}
                                >
                                    <option value="">Виберіть статус</option>
                                    <option value="active">Активний</option>
                                    <option value="disabled">Не активний</option>
                                    <option value="draft">Draft</option>
                                    <option value="verification-pending">Чекає на верифікацію</option>
                                </select>
                                <label className={s_Select.label} htmlFor="user-status">Статус активності</label>
                            </div>
                            {form.errors?.status
                            && <p className="form-input-hint">{form.errors.status?.message}</p>}
                        </div>
                    </ColFull>
                </Group>

                {/* domain_id */}
                <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!formProps?.data?.domains?.length
                                    ? (
                                        <select
                                            name="domain_id"
                                            className={cn(s_Select.select, {
                                                [s_Select.select_error]: form.errors?.domain_id,
                                            })}
                                            id="user-domain-id"
                                            ref={form.register({
                                                required: VALIDATION.select.required,
                                            })}
                                        >
                                            <option value="">виберіть домен</option>
                                            {formProps?.data?.domains?.map((domain) => (
                                                <option
                                                    key={domain.id}
                                                    value={domain.id}
                                                    selected={domain.id === dataFields?.domain_id}
                                                >
                                                    {domain.title}
                                                </option>
                                            ))}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading" />
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="user-domain-id">Домен</label>
                            </div>
                            {form.errors?.domain_id
                            && <p className="form-input-hint">{form.errors.domain_id?.message}</p>}
                        </div>
                    </ColFull>
                </Group>
            </Block>
        </form>
    );
};

export default TabInfoUser;
