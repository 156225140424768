import fetchAx from '../fetchAx';
import {
    ORDERS_GET,
    ORDER_CREATE,
    ORDER_GET_EDIT,
    ORDER_EDIT,
    ORDER_DELETE,
    WH_GET,
    DOMAIN_API,
    ORDER_PROMO_CODE_EDIT,
    ORDER_PROMO_CODE_DELETE,
    SMS_GET,
    SMS_SEND,
    ORDER_WRIT_OFF_BONUSES,
    ORDER_SPLIT,
    SHIPPING_ACTIONS_GET,
    CREATE_TTN,
    ORDER_RECEIPT_GET,
    ORDER_RECEIPT_CREATE,
    ORDER_RECEIPT_RETURN,
    ORDER_RECEIPT_STATUS_EDIT,
    ORDER_VISIT_GET,
    ORDERS_SOURCES_GET,
    DELIVERY_STATUSES_GET,
    ORDER_SUBORDER_SERVICE,
    ORDER_SERVICES_GET,
    ORDER_COUPON_PKS_DELETE,
    ORDER_COUPON_PKS,
    ORDER_SIMILAR_GET_EDIT,
    ORDER_INVOICE,
    PAYMENT_PROVIDERS_GET,
    ORDER_GET_BINOTEL_CALLS,
    ORDER_COUPON_VISA,
    ORDER_COUPON_VISA_DELETE,
    ORDER_EXCEL_MODES_GET, ORDER_CLONE_CREATE,
    ORDER_PROMO_CODE_STUDENT_EDIT,
} from '../../const/api';
import { LIST_PER_PAGE } from "../../const/view";

export const getOrders = (params) => fetchAx({
    url: ORDERS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const getOrdersStatusMonoCheckout = (params) => fetchAx({
    url: ORDERS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        status: 34,
        ...params,
    },
});

export const createOrder = (data) => fetchAx({
    url: ORDER_CREATE,
    method: 'POST',
    data,
});

export const cloneOrder = (id) => fetchAx({
    url: ORDER_CLONE_CREATE.join(id),
    method: 'POST'
});

export const getOrderEdit = (id) => fetchAx({
    url: ORDER_GET_EDIT.join(id),
    method: 'GET',
});

export const getOrderSimilarEdit = (id) => fetchAx({
    url: ORDER_SIMILAR_GET_EDIT.join(id),
    method: 'GET',
});

export const checkVisitOrderEdit = (id) => fetchAx({
    url: ORDER_VISIT_GET.join(id),
    method: 'GET',
});

export const editOrder = (id, data) => fetchAx({
    url: ORDER_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeOrder = (id) => fetchAx({
    url: ORDER_DELETE.join(id),
    method: 'DELETE',
});

export const getLogOrder = (id) => fetchAx({
    url: `${ORDERS_GET}${id}/log`,
    method: 'GET',
});

export const getOrderSources = () => fetchAx({
    url: ORDERS_SOURCES_GET,
    method: 'GET',
});

export const getDeliveriesStatuses = () => fetchAx({
    url: DELIVERY_STATUSES_GET,
    method: 'GET',
});

export const getWarehouse = (params) => fetchAx({
    url: WH_GET,
    method: 'GET',
    params: {
        ...params,
        per_page: 1000
    }
});

export const addProductInOrder = (orderId, jde) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/add-product/${jde}`,
    method: 'POST',
});

export const addOrderPromoCodeStudent = (id, data) => fetchAx({
    url: ORDER_PROMO_CODE_STUDENT_EDIT.join(id),
    method: 'POST',
    data,
});

export const addOrderPromoCode = (id, data) => fetchAx({
    url: ORDER_PROMO_CODE_EDIT.join(id),
    method: 'POST',
    data,
});

export const removeOrderPromoCode = (id) => fetchAx({
    url: ORDER_PROMO_CODE_DELETE.join(id),
    method: 'DELETE',
});

export const getSms = () => fetchAx({
    url: SMS_GET,
    method: 'GET',
});

export const getPaymentProviders = (id) => fetchAx({
    url: PAYMENT_PROVIDERS_GET.join(id),
    method: 'GET'
});

export const sendSms = (data) => fetchAx({
    url: SMS_SEND,
    method: 'POST',
    data,
});

export const writOffBonuses = (id, data) => fetchAx({
    url: ORDER_WRIT_OFF_BONUSES.join(id),
    method: 'POST',
    data
});

export const splitOrder = (id, data) => fetchAx({
    url: ORDER_SPLIT.join(id),
    method: 'PUT',
    data
});

export const getShippingActions = (id) => fetchAx({
    url: SHIPPING_ACTIONS_GET.join(id),
    method: 'GET',
});

export const createTtn = (id, data) => fetchAx({
    url: CREATE_TTN.join(id),
    method: 'POST',
    data
});

export const getOrderReceipt = (id) => fetchAx({
    url: ORDER_RECEIPT_GET.join(id),
    method: 'GET',
});

export const createOrderReceipt = (id) => fetchAx({
    url: ORDER_RECEIPT_CREATE.join(id),
    method: 'POST',
});

export const createOrderInvoice = (id, data) => fetchAx({
    url: ORDER_INVOICE.join(id),
    method: 'POST',
    data
});

export const orderReceiptReturn = (id, data) => fetchAx({
    url: ORDER_RECEIPT_RETURN.join(id),
    method: 'POST',
    data
});

export const orderReceiptStatus = (orderId, receiptId) => fetchAx({
    url: ORDER_RECEIPT_STATUS_EDIT(orderId, receiptId),
    method: 'PUT'
});

export const getOrderProductServices = (id, orderProductId, params) => fetchAx({
    url: ORDER_SERVICES_GET(id, orderProductId),
    method: 'GET',
    params,
});

export const getGifts = (orderID, productId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderID}/products/${productId}/gifts`,
    method: 'GET',
});

export const getBundles = (orderID, productId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderID}/products/${productId}/bundles`,
    method: 'GET',
});

export const getDiscountSpecialOffers = (orderID, productId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderID}/products/${productId}/discount-special-offers`,
    method: 'GET',
});

export const createSuborderService = (id, data) => fetchAx({
    url: ORDER_SUBORDER_SERVICE.join(id),
    method: 'POST',
    data,
});

/* PKS */

export const applyOrderCouponPks = (id) => fetchAx({
    url: ORDER_COUPON_PKS.join(id),
    method: 'PUT',
});

export const removeOrderCouponPks = (id) => fetchAx({
    url: ORDER_COUPON_PKS_DELETE.join(id),
    method: 'DELETE',
});

export const applyOrderCouponVisa = (id) => fetchAx({
    url: ORDER_COUPON_VISA.join(id),
    method: 'PUT',
});

export const removeOrderCouponVisa = (id) => fetchAx({
    url: ORDER_COUPON_VISA_DELETE.join(id),
    method: 'DELETE',
});

export const getCallsHistory = (id) => fetchAx({
    url: ORDER_GET_BINOTEL_CALLS.join(id),
    method: 'GET',
});

export const getOrderExcelModes = () => fetchAx({
    url: ORDER_EXCEL_MODES_GET,
    method: 'GET'
})
