import {useLocation, useNavigate} from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import {getData, getFiltersData, remove} from "./api";
import {Helmet} from "react-helmet";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import getSplicedData from "../../lib/crud/getSplicedData";
import getTZtoDate from "../../lib/getTZtoDate";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";
import React, {useEffect, useRef, useState} from "react";
import s from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";

const ClaimList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    filtersData,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  const ref = useRef(null);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = async () => {
    if (ref?.current?.checked) {
      setSelectAll(true);
      const temp = rest?.data?.map(e => e.id);
      setSelected(temp);
    } else {
      setSelectAll(false);
      setSelected([]);
    }
  };

  const handleSelect = (id) => {
    const temp = [...selected];
    const index = temp.indexOf(id);

    if (index !== -1) {
      temp.splice(index, 1);
      setSelected(temp);
    } else setSelected((prev) => ([...prev, id]));
  };

  useEffect(() => {
    if (!!selected?.length) {
      localStorage.setItem('ids', JSON.stringify(selected));
    } else {
      localStorage.removeItem('ids');
    }
  }, [selected]);

  return (
    <div>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Subscriptions list`}/>
      {!load && (
        <TopPagination
          pagination={(
            <>
              {rest?.meta?.total > 0 && (
                <>
                  <TableListViewPagination
                    length={+rest?.meta?.last_page}
                    current={+rest?.meta?.current_page}
                    loc={location}
                    navigate={navigate}
                  />
                  <TableListViewPerPage
                    urlParams={urlParams}
                    onSelect={(val, params) => pushUrlParams(params)}
                    total={+rest?.meta?.total}
                  />
                </>
              )}
            </>
          )}
        />
      )}
      <TableListViewTable noSticky>
        <TableHead
          arrayChildren={[
            '', 'ID', "ПІБ", 'Телефон', 'Email', 'Номер заявки', 'СЦ який видав', 'Промокод', 'Створено'
          ]}
          filterChildren={<TableListViewFiltersRow
            urlParams={urlParams}
            fields={[
              {
                type: 'checkbox', name: 'select_all', id: 'table-select_all', attr: {
                  onClick: () => handleSelectAll(),
                  defaultChecked: selectAll,
                  ref
                }
              },
              {type: 'text', name: 'id', width: '5%'},
              {type: 'text', name: 'name'}, // ПБИ
              {type: 'text', name: 'phone'},
              {type: 'text', name: 'email'},
              {type: 'text', name: 'scid'}, // Номер заявки
              {
                type: 'custom_select_for_promocode',
                name: 'service_center_id',
                options: !!filtersData?.services?.data?.length && filtersData?.services?.data,
                optProps: ["id", "title_ua", 'code']
              }, // Сц який видав
              {type: 'text', name: 'coupon'}, // Промкод
              {type: 'date_range', startName: 'created_at_from', endName: 'created_at_to', width: '18%'}
            ]}
            onSubmit={pushUrlParams}
          />}
          showAction={false}
        />
        <tbody>
        {load
          ? (
            <TableListViewRowLoader cols={8}/>
          )
          : (rest && (
            !!rest?.data?.length
              ? rest?.data?.map((e, i) => (
                <TableListViewRow
                  id={e.id}
                  key={`subscriber-item-${e.id}`}
                  remove={{
                    api: remove,
                    success: () => setRest(getSplicedData(i, rest)),
                    alert: 'Подписка удалена',
                  }}
                  editLink={`/app/subscriber/update/${e.id}`}
                  controlsOff
                >
                  <td>
                    <div className={s.checkbox__wrapper}>
                      <input
                        className={s.checkbox}
                        type="checkbox"
                        id={`table_product_${i}`}
                        name={`product_${i}`}
                        checked={selected?.find((i) => i === e.id)}
                        onClick={() => handleSelect(e.id)}
                      />
                      <label htmlFor={`table_product_${i}`}
                             className={`${s.label} ${s_Table.label}`}/>
                    </div>
                  </td>
                  <td>
                    <span>
                      {e?.id || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e?.name || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e?.phone || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e?.email || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e?.scid || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e?.service_center?.id ? `[ ${e?.service_center?.code} ] ` + e?.service_center.title_ua : '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e?.cart_rule_coupon?.code || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {getTZtoDate(e?.created_at, true)}
                    </span>
                  </td>
                </TableListViewRow>
              ))
              : (
                <TableListViewRow disabled>
                  <td colSpan={8}>Немає подписок</td>
                </TableListViewRow>
              )
          ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default ClaimList;