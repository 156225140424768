import Constants from './constants'

export const initialSate = {
    domains: [],
    categoryTree: []
};

const reducer = (state = initialSate, action) => {
    switch (action.type) {
        case Constants.CHANGE_DOMAIN_LIST:
            return {
                ...state,
                domains: action.payload
            };

        case Constants.CHANGE_CATEGORY_TREE_LIST:
            const parseCategoriesAction = []

            const parseCategoriesActionNodes = (nodes) => {
                if (!!nodes.children?.length) {
                    nodes.children.map((node) => parseCategoriesActionNodes(node))
                }

                return parseCategoriesAction.push({
                    id: nodes.id,
                    title: nodes.title
                })
            }

            parseCategoriesActionNodes(action.payload)

            return {
                ...state,
                categoryTree: parseCategoriesAction
            };

        default:
            return state;
    }
};

export default reducer;
