import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import routes from './routes'
import { isAuth, isAuthenticated } from './lib/hooks/useAuth'
import { getCategoryTreeListThunk, getDomainListThunk } from './store/filters/thunks'

function App() {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { pathname } = useLocation()

  if(!localStorage?.tokenData){
    if(!pathname.includes('/login')) {
      let currentUrl = `${window.location.pathname}${window.location.search}`
      localStorage.setItem('followingLink', currentUrl)
    }
  }

  if(localStorage.followingLink && localStorage.tokenData){
    window.location.href = localStorage.getItem('followingLink')
  }

  if(!localStorage.getItem('per_page')) {
    localStorage.setItem('per_page', '20')
  }

  useEffect(() => {
    localStorage.setItem('canRefresh', 'true')
    isAuth(navigate)
  }, [isAuthenticated])

  useEffect(() => {
    dispatch(getDomainListThunk())
    dispatch(getCategoryTreeListThunk())
  }, [])

  const routing = useRoutes(routes(isAuthenticated()))
  return routing
}

export default App
