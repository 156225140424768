import React, { useEffect, useState } from 'react'
import s from './DateRangeCustom.module.scss'
import s_input from '../InputWrapper/InputWrapper.module.scss'
import { INPUT_MAX_LENGTH } from '../../../const/view'
import InputWrapper from '../InputWrapper'

const DateRangeCustom = ({ startDateName, endDateName, formH, urlParams, handlerOnChange, style }) => {
    const [prevDateFrom, setPrevDateFrom] = useState('')
    const [prevDateTo, setPrevDateTo] = useState('')

    const handleDate = (e, prevDate, setPrevDate) => {
        const newDate = e.target.value;

        const splitDate = prevDate.split('-')
        const splitCurrentDate = newDate.split('-')

        if (newDate !== prevDate) {
            setPrevDate(newDate)
            if (splitDate[2] !== splitCurrentDate[2]) {
                handlerOnChange()
            }
        }
    }

    useEffect(() => {
        if (urlParams?.[startDateName]) setPrevDateFrom(urlParams[startDateName])

        if (urlParams?.[endDateName]) setPrevDateTo(urlParams[endDateName])
    }, [urlParams])

    return (
        <div className={s.wrap} style={style}>
            <InputWrapper id={`filter-table-${startDateName}`}>
                <input
                    className={s_input.input}
                    type="date"
                    maxLength={INPUT_MAX_LENGTH}
                    name={startDateName}
                    ref={formH.register}
                    id={`filter-table-${startDateName}`}
                    defaultValue={urlParams && urlParams[startDateName]}
                    onChange={(e) => handleDate(e, prevDateFrom, setPrevDateFrom)}
                />
            </InputWrapper>
            {!style?.height && <div>-</div>}
            <InputWrapper id={`filter-table-${startDateName}`}>
                <input
                    className={s_input.input}
                    type="date"
                    maxLength={INPUT_MAX_LENGTH}
                    name={endDateName}
                    ref={formH.register}
                    id={`filter-table-${endDateName}`}
                    defaultValue={urlParams && urlParams[endDateName]}
                    onChange={(e) => handleDate(e, prevDateTo, setPrevDateTo)}
                />
            </InputWrapper>
        </div>
    );
};

export default DateRangeCustom;