import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import { getData, remove, getFiltersData } from "./api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import ModalDocument from "./Modal/ModalDocument";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import TableListViewRowLoader from "../../components/ui/DragComponents/Row/RowLoader";
import TableListViewRow from "../../components/ui/DragComponents/Row";
import { editDocumentTo, refreshDocument } from "../../lib/api/documents";
import errorMessage from "../../lib/errorMessage";
import { toast } from "react-toastify";
import TopPanelNew from "../../components/common/TopPanelNew";
import checkPermission from "../../lib/checkPermission";
import Button from "../../components/ui/Button";

const DocumentsList = () => {
    const [activeModal, setActiveModal] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();

    const {
        rest,
        load,
        filtersData,
        urlParams,
        setRest,
        setLoad,
        pushUrlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted,
    });

    const [activeRowId, setActiveRowId] = useState(null);

    const itemsIds = useMemo(() => rest?.data?.map(({ id }) => id), [rest?.data]);

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    const handleDragStart = (event) => {
        setActiveRowId(event.active.id);
    }

    const handleDragEnd = async (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const newIndex = itemsIds.indexOf(over.id);
            setRest((prev) => {
                const temp = {...prev};
                const oldIndex = itemsIds.indexOf(active.id);
                const newIndex = itemsIds.indexOf(over.id);
                return {
                    ...temp,
                    data: arrayMove(temp?.data, oldIndex, newIndex)
                }
            });
            await editDocumentTo(active.id, newIndex + 1).then(res => {
                toast('Позиція оновлена', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setRest((prev) => {
                    const temp = {...prev};
                    return {
                        ...temp,
                        data: res?.data?.data
                    }
                });
            }).catch(async (err) => await errorMessage(err, ''));
        }

        setActiveRowId(null);
    }

    const handleDragCancel = () => {
        setActiveRowId(null);
    }

    const handleRefresh = async () => {
        setLoad(true);
        await refreshDocument({
            domain_id: urlParams?.['domain_id'] || '1',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page
        })
            .then((res) => {
                setRest(prev => {
                    const temp = {...prev};

                    temp.data = res.data?.data;

                    return temp;
                });
                toast('Рефреш виконано', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    return (
        <>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Documents list`}/>
            <TopPanelNew
                title='Куточок споживача'
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app'
                        },
                        {
                            title: 'Куточок споживача',
                            url: `/app/documents${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`
                        }
                    ]
                }}
                ModalCustom={ModalDocument}
                btnModal={{
                    title: 'Створити',
                    permissionName: 'admin.documents.store'
                }}
                customButtonTopFirst={
                    <div style={{
                        display: checkPermission('admin.sort.special-offers.refresh')
                            ? 'flex'
                            : 'none',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px'
                    }}>
                        <Button onClick={handleRefresh} purple>
                            Рефреш сортування
                        </Button>
                    </div>
                }
            />
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {rest?.meta?.total >= 0 && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
            >
                <TableListViewTable>
                    <TableHead
                        arrayChildren={[
                            'ID', 'Позиція', 'Назва документа', 'Посилання', 'Статус активності'
                        ]}
                        filterChildren={<TableListViewFiltersRow
                            urlParams={urlParams}
                            fields={[
                                { },
                                { },
                                { },
                                { },
                                { type: 'select', name: 'is_active', options: [
                                        {
                                            id: 0,
                                            title: 'Не активна'
                                        },
                                        {
                                            id: 1,
                                            title: 'Активна'
                                        }
                                    ]
                                },
                                { }
                            ]}
                            onSubmit={pushUrlParams}
                        />}
                    />
                    <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={6} />
                        )
                        : (rest && (
                            rest?.data?.length
                                ? (
                                    <SortableContext items={rest?.data} strategy={verticalListSortingStrategy}>
                                        {rest?.data?.map((e, i) => (
                                            <TableListViewRow
                                                id={e.id}
                                                index={i}
                                                key={`document-item-${e.id}`}
                                                remove={{
                                                    api: remove,
                                                    success: () => setRest(getSplicedData(i, rest)),
                                                    alert: 'Документ видалено',
                                                }}
                                                editLink={`/app/documents/update/${e.id}`}
                                                permissionRemove='admin.documents.destroy'
                                                permissionEdit='admin.documents.edit'
                                                setActiveModal={setActiveModal}
                                                setActiveId={setActiveId}
                                                tagA={false}
                                            >
                                                <td>
                                                    <span>
                                                        {e?.position || 0}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {e.title_ua || '---'}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {e?.url
                                                            ? <a href={e.url} target="_blank">{e?.url}</a>
                                                            : '---'}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>
                                                        {e.is_active
                                                            ? <StatusCircle green text="Активна"/>
                                                            : <StatusCircle red text="Не активна"/>}
                                                    </span>
                                                </td>
                                            </TableListViewRow>
                                        ))}
                                    </SortableContext>
                                )
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={6} style={{paddingTop: '22px'}}>Немає документів</td>
                                    </TableListViewRow>
                                )
                        ))}
                    </tbody>
                </TableListViewTable>
            </DndContext>

            {activeModal && (
                <ModalDocument
                    title="Редагувати документ"
                    active={activeModal}
                    setActive={setActiveModal}
                    editId={activeId}
                />
            )}
        </>
    );
};

export default DocumentsList;
