import { setOnChangeFiltersCategoryTreeList, setOnChangeFiltersDomainList } from './action'
import { getDomains } from '../../lib/api/domains'
import { getCatTree } from '../../lib/api/categories'

export const getDomainListThunk = () => async dispatch => {
	const res = await getDomains()

	if (res.status === 200) {
		dispatch(setOnChangeFiltersDomainList(res.data))
	} else {
		dispatch(setOnChangeFiltersDomainList([]))
	}
}

export const getCategoryTreeListThunk = () => async dispatch => {
	const res = await getCatTree()

	if (res.status === 200) {
		dispatch(setOnChangeFiltersCategoryTreeList(res?.data?.[0]))
	} else {
		dispatch(setOnChangeFiltersCategoryTreeList([]))
	}
}
