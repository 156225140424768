import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, remove } from "./api";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import { geDomainName } from "../../lib/const/domains";
import CopyComponent from "../../components/ui/CopyComponent";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import { domainsListSelector } from "../../store/filters/selectors";

const SpecOfferBannerList = () => {
    const domainList = useSelector(domainsListSelector)
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();

    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted,
    });

    /* eslint-disable */
    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Spec offer banners list`}/>
            {!load && (
                <TopPagination
                    tabs={(
                        <TopNavTabs tabs={[
                            {
                                title: 'Акції',
                                url: '/app/spec-offer-banners',
                                permissionName: 'admin.special-offer_banner.index',
                                isActive: true
                            },
                            {
                                title: 'Теги акцій',
                                url: '/app/spec-offer-banners/tag',
                                permissionName: 'admin.special-offer.banners.tag.index.index'
                            }
                        ]}
                        />
                    )}
                    pagination={(
                        <>
                            {rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Назва УКР', 'Назва РУС', 'URL', 'Домен', 'Зображення PC', 'Зображення Mobile',
                        'Термін дії від', 'Термін дії до', 'Статус активність'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'title_ua'},
                            {type: 'text', name: 'title_ru'},
                            {},
                            {type: 'select', name: 'domain_id', options: !!domainList?.length ? domainList : []},
                            {},
                            {},
                            { type: 'date', name: 'date_from'},
                            { type: 'date', name: 'date_to' },
                            {
                                type: 'select',
                                name: 'is_active',
                                options: [
                                    {
                                        id: 0,
                                        title: 'Не активна',
                                    },
                                    {
                                        id: 1,
                                        title: 'Активна',
                                    },
                                ],
                            },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={10}/>
                    )
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => {

                                let urlSpecOffer = '';

                                if (!!domainList?.length) {
                                    urlSpecOffer = `${e?.domain?.domain}${e?.domain?.id === 1
                                        ? '/specialoffer/'
                                        : e?.domain?.id === 2
                                            ? '/promotions/'
                                            : '/specialoffer/'
                                    }${e?.url}.html`
                                }

                                return (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`spec-offer-banner-item-${e.id}`}
                                        remove={{
                                            api: remove,
                                            success: () => setRest(getSplicedData(i, rest)),
                                            alert: 'Банер акції видалено',
                                        }}
                                        editLink={`/app/spec-offer-banner/update/${e.id}`}
                                        permissionRemove='admin.special-offer_banner.destroy'
                                        permissionEdit='admin.special-offer_banner.edit'
                                    >
                                        <td>
                                            {checkPermission('admin.special-offer_banner.edit')
                                                ? <Link to={`/app/spec-offer-banner/update/${e.id}`}>{e.id}</Link>
                                                : <span>{e.id}</span>
                                            }
                                        </td>
                                        <td>
                                            <span>
                                                {e?.title_ua || '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.title_ru || '---'}
                                            </span>
                                        </td>
                                        <td>
                                            {e?.url ? <CopyComponent copyText={urlSpecOffer}/> : '---'}
                                        </td>
                                        <td>
                                            <span>
                                                {e?.domain_id ? geDomainName(e.domain_id)?.name : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            {e?.cover && <img src={e?.cover?.original || e?.cover?.conversions?.original} alt=""/>}
                                        </td>
                                        <td>
                                            {e?.mobile && <img src={e?.mobile?.original || e?.mobile?.conversions?.original} alt=""/>}
                                        </td>
                                        <td>
                                            <span>
                                                {e?.date_from ? getTZtoDate(e.date_from, true) : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.date_to ? getTZtoDate(e.date_to, true) : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                            </span>
                                        </td>
                                    </TableListViewRow>
                                )
                            })
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={10} style={{textAlign: "center"}}>Немає банерів акцій</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default SpecOfferBannerList;
