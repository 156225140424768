import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import s_Modal from "./modal.module.scss";
import Button from "../../../components/ui/Button";
import CheckboxSwitch from "../../../components/ui/CheckboxSwitch";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import { createDocument, editDocument, getDocumentEdit } from "../../../lib/api/documents";
import errorMessage from "../../../lib/errorMessage";
import InputFileDocument from "../../../components/ui/InputFileDocument";
import Textarea from "../../../components/ui/Textarea";
import loadMedia from "../../../lib/helpers/loadMedia";
import Loader from "../../../components/ui/Loader";

const ModalDocument = ({ active, setActive, title, editId }) => {
    const [load, setLoad] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState('');

    const formH = useForm({
        mode: 'onChange',
        defaultValues: {
            title_ua: '',
            position: 1,
            type: '',
            url: '',
            is_active: false
        }
    });

    const {
        register,
        errors,
        watch,
        handleSubmit,
        setValue
    } = formH;

    const isActiveWatch = watch('is_active')

    const submitHandler = async (data) => {
        setLoad(true)
        if (editId) {
            if (data?.file) {
                data.url = null
            }

            if (data.url) {
                data.file = null
            }

            await editDocument(editId, { ...data, type: 'buyers-corner' })
                .then((res) => window.location.reload())
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false))
        } else {
            await createDocument({ ...data, type: 'buyers-corner' }).then(async (resCreate) => {
                if (file && resCreate?.data?.id) {
                    await loadMedia(
                        file,
                        () => window.location.reload(),
                        'PNG, JPG, PDF',
                        '1',
                        'images',
                        resCreate?.data?.id,
                        'document');
                } else {
                    window.location.reload();
                }
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false))
        }
    }

    useEffect(() => {
        if (editId) {
            setLoad(true);
            getDocumentEdit(editId).then((res) => {
                setValue('title_ua', res?.data?.data?.title_ua);
                setValue('title_ru', res?.data?.data?.title_ua);
                setValue('position', res?.data?.data?.position);
                setValue('is_active', !!res.data?.data.is_active);
                setValue('url', res.data?.data.url);
                setUrl(res.data?.data.url)

                if (res?.data?.data?.file) {
                    const parseName = res?.data?.data?.file?.original?.split('/');
                    const nameDoc = parseName[parseName?.length - 1];
                    setFileData({
                        id: res?.data?.data?.file?.id,
                        url: res?.data?.data?.file?.original,
                        title: nameDoc
                    })
                }
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [editId])

    return (
        <div
            className={cn(s_Modal.modal, {
                [s_Modal.active]: active
            })}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title ? title : 'Новий документ'}</div>
                        </div>
                        <div>
                            {/* is_active */}
                            <CheckboxSwitch
                                name="is_active"
                                id="document-is-active"
                                formH={formH}
                                defaultChecked={isActiveWatch}
                            />
                        </div>
                    </div>
                    <div>
                        {/* title */}
                        <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <Textarea
                                    name="title_ua"
                                    id="document-title-ua"
                                    placeholder="Назва документа"
                                    formH={formH}
                                />
                            </ColFull>
                        </Group>

                        {/* position */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Позиція"
                                    id="document-position"
                                    errorComponent={errors?.position &&
                                    <p className="form-input-hint">{errors?.position?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.position,
                                        })}
                                        type="number"
                                        placeholder="Позиція"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="position"
                                        id="document-position"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* url */}
                        {((fileData === null) && (file === null)) && (
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputWrapper label="Посилання" id="document-url">
                                        <input
                                            className={s_Input.input}
                                            type="text"
                                            placeholder="Посилання"
                                            ref={register}
                                            name="url"
                                            id="document-url"
                                            value={url}
                                            onChange={(e) => setUrl(e.target.value)}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                        )}

                        {/* file */}
                        {!url?.length && (
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputFileDocument
                                        name="file"
                                        val={fileData}
                                        formH={formH}
                                        ref={register}
                                        acceptStr="PNG, JPG, PDF"
                                        accept="image/*,.pdf"
                                        sizeMB={5}
                                        placeHolder="Завантажити документ"
                                        className="d-flex"
                                        documentId={editId ? editId : 1}
                                        setFileAfterSave={editId ? false : setFile}
                                        setFileData={setFileData}
                                        border
                                    />
                                </ColFull>
                            </Group>
                        )}
                    </div>
                    <div className={s_Modal.modal__footer}>
                        <div className={s_Modal.modal__buttons}>
                            <Button purple type='submit' disabled={load}>{editId ? 'Зберегти' : 'Додати'}</Button>
                            <Button border onClick={() => setActive(false)}>Скасувати</Button>
                        </div>
                    </div>
                </form>
            </div>
            {load && <Loader />}
        </div>
    );
};

export default ModalDocument;