import Constants from './constants'

export const setOnChangeFiltersDomainList = (list) => ({
    type: Constants.CHANGE_DOMAIN_LIST,
    payload: list
})

export const setOnChangeFiltersCategoryTreeList = (list) => ({
    type: Constants.CHANGE_CATEGORY_TREE_LIST,
    payload: list
})
