import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableHead from '../../components/common/TableListViewNew/TableHead';
import TableListViewPagination from '../../components/common/TableListViewNew/Pagination';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from '../../components/common/TableListViewNew/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import { getData, remove, getFiltersData } from './api';
import TopNavTabs from '../../components/ui/TopNavTabsNew/TopNavTabs';

import { geDomainName } from '../../lib/const/domains';
import checkPermission from "../../lib/checkPermission";
import { BANNER_MAIN_PAGE_CREATE, DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import getTZtoDate from "../../lib/getTZtoDate";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import TopPanelNew from "../../components/common/TopPanelNew";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";

const BannerManagementHomeList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted
    });

    return (
        <>
            <TopPanelNew
                title="Управління банерами"
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app'
                        },
                        {
                            title: 'Управління банерами',
                            url: '/app/banner-management'
                        },
                        {
                            title: 'Управління банерами для головної сторінки',
                            url: `/app/banner-management/home${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`
                        }
                    ]
                }}
                btnModal={{
                    title: 'Створити',
                    api: BANNER_MAIN_PAGE_CREATE,
                    href: '/app/banner-management/home/update',
                    permissionName: 'admin.banner-main-page.store',
                    fields: [
                        {type: 'text', name: 'title_ua', label: 'Назва банера УКР', placeholder: 'Назва банера УКР'},
                        {type: 'text', name: 'title_ru', label: 'Назва банера РУС', placeholder: 'Назва банера РУС'}
                    ],
                    defaultSendValue: {
                        domain_id: 1
                    }
                }}
                customButtonTopFirst={<div>
                    {/* is_active */}
                    <CheckboxSwitch
                        name="is_active"
                        id="b-is-active"
                        label="Показати тільки нові"
                        defaultChecked={!!urlParams?.visibility}
                        onClickFunc={(prev) => {
                            console.log(urlParams, 'urlParams f')
                            if (prev) {
                                urlParams.visibility  = 'valid';
                                if (!urlParams?.domain_id) {
                                    urlParams.domain_id = '1';
                                }
                            } else {
                                urlParams.visibility  = 'all';
                            }
                            pushUrlParams(urlParams);
                        }}
                    />
                </div>}
                // btnClearParams={{
                //     title: 'Сбросить фильтры',
                //     href: '/app/banner-management/home',
                //     permissionName: 'admin.banner-main-page.index',
                // }}
            />
            <div style={{margin: 0}}>
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner home list`}/>
                {!load && (
                    <TopPagination
                        tabs={(
                            <TopNavTabs tabs={[
                                {
                                    title: 'Банер головна сторінка',
                                    url: '/app/banner-management/home',
                                    permissionName: 'admin.banner-main-page.index',
                                    isActive: true
                                },
                                {
                                    title: 'Банер меню',
                                    url: '/app/banner-management/menu',
                                    permissionName: 'admin.banner-menu.index'
                                },
                                {
                                    title: 'Горизонтальний банер каталогу',
                                    url: '/app/banner-management/catalog-horizontal',
                                    permissionName: 'admin.banner-catalog-horizontal.index'
                                },
                                {
                                    title: 'Банер над шапкою сайту',
                                    url: '/app/banner-management/head',
                                    permissionName: 'admin.banner-header.index'
                                },
                                {
                                    title: 'Вертикальний банер каталогу',
                                    url: '/app/banner-management/catalog',
                                    permissionName: 'admin.banner-catalog.index'
                                }
                            ]}
                            />
                        )}
                        pagination={(
                            <>
                                {+rest?.meta?.total && (
                                    <>
                                        <TableListViewPagination
                                            length={+rest?.meta?.last_page}
                                            current={+rest?.meta?.current_page}
                                            loc={location}
                                            navigate={navigate}
                                        />
                                        <TableListViewPerPage
                                            urlParams={urlParams}
                                            onSelect={(val, params) => pushUrlParams(params)}
                                            total={+rest?.meta?.total}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    />
                )}
                <TableListViewTable>
                    <TableHead
                        arrayChildren={[
                            'ID', 'Назва банера УКР', 'Назва банера РУС', 'Зображення PC', 'Зображення Mobile',
                            'Позиція', 'Дата початку', 'Дата закінчення', 'Домен', 'Статус активності'
                        ]}
                        filterChildren={<TableListViewFiltersRow
                            urlParams={urlParams}
                            fields={[
                                {},
                                {type: 'text', name: 'title_ua'},
                                {type: 'text', name: 'title_ru'},
                                {},
                                {},
                                {},
                                { type: 'date', name: 'date_from'},
                                { type: 'date', name: 'date_to' },
                                {
                                    type: 'select', name: 'domain_id', options: filtersData?.domains
                                },
                                {},
                                {}
                            ]}
                            onSubmit={pushUrlParams}
                        />}
                    />
                    <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={11}/>
                        )
                        : (rest && (
                            rest?.data?.length
                                ? rest?.data?.map((e, i) => (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`banner-management-item-${e.id}`}
                                        remove={{
                                            api: remove,
                                            success: () => setRest(getSplicedData(i, rest)),
                                            alert: 'Банер головної сторінки видалено',
                                        }}
                                        editLink={`/app/banner-management/home/update/${e.id}`}
                                        permissionRemove='admin.banner-main-page.destroy'
                                        permissionEdit='admin.banner-main-page.edit'
                                    >
                                        <td>
                                            {checkPermission('admin.banner-main-page.edit')
                                                ? <Link to={`/app/banner-management/home/update/${e.id}`}>{e.id}</Link>
                                                : <span>{e.id}</span>
                                            }
                                        </td>
                                        <td>
                                <span>
                                  {e.title_ua || '---'}
                                </span>
                                        </td>
                                        <td>
                                <span>
                                  {e.title_ru || '---'}
                                </span>
                                        </td>
                                        <td>{e?.cover_ua &&
                                        <img src={e?.cover_ua?.original || e?.cover_ua?.conversions?.original} alt=""/>}</td>
                                        <td>{e?.mobile_ua &&
                                        <img src={e?.mobile_ua?.original || e?.mobile_ua?.conversions?.original} alt=""/>}</td>
                                        <td>
                                  <span>
                                    {e?.position || 0}
                                  </span>
                                        </td>
                                        <td>
                                  <span>
                                    {e?.date_from ? getTZtoDate(e.date_from, true) : '---'}
                                  </span>
                                        </td>
                                        <td>
                                  <span>
                                    {e?.date_to ? getTZtoDate(e.date_to, true) : '---'}
                                  </span>
                                        </td>
                                        <td>
                                  <span>
                                    {e?.domain_id ? geDomainName(e.domain_id)?.name : '---'}
                                  </span>
                                        </td>
                                        <td>
                                  <span>
                                    {e.is_active ? <StatusCircle green text="Активна"/> :
                                        <StatusCircle red text="Не активна"/>}
                                  </span>
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={10} style={{paddingTop: '22px'}}>Немає банерів головної сторінки</td>
                                    </TableListViewRow>
                                )
                        ))}
                    </tbody>
                </TableListViewTable>
            </div>
        </>
    )
};

export default BannerManagementHomeList;
