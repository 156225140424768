import React, { forwardRef, useEffect, useState } from "react";
import cn from "classnames";
import { ERROR_FILE_SIZE_INVALID } from "../../../const/text";
import parseStrPat from "../../../lib/parseStrPat";
import getMbToBytes from "../../../lib/helpers/getMbToBytes";

import s from "./InputFileDocument.module.scss";
import icon_delete from "../../../assets/icons/icon_delete.svg";
import icon_document_pdf from "../../../assets/icons/icon_document_pdf.svg";
import loadMedia from "../../../lib/helpers/loadMedia";
import errorMessage from "../../../lib/errorMessage";
import { removeMedia } from "../../../lib/api/media";
import { toast } from "react-toastify";
import Loader from "../Loader";

const InputFileDocument = forwardRef(({
                                          name,
                                          val,
                                          defVal,
                                          sm,
                                          onClear,
                                          acceptStr,
                                          accept,
                                          sizeMB = 5,
                                          className,
                                          removeBtn = true,
                                          border = false,
                                          placeHolder,
                                          documentId,
                                          setFileAfterSave = false,
                                          setFileData = false
                                      }, ref) => {
    const [document, setDocument] = useState(null);
    const [load, setLoad] = useState(false);

    async function fileHandler(e) {
        const fList = e.target.files;
        if (!fList.length) return;

        // sizeMB validation
        if (sizeMB && fList[0].size > getMbToBytes(sizeMB)) {
            alert(parseStrPat(ERROR_FILE_SIZE_INVALID, {n: `${sizeMB}мб`}));
            return;
        }

        if (setFileAfterSave) {
            setDocument({
                title: fList[0]?.name
            });
            setFileAfterSave(fList[0]);
            if (setFileData) setFileData(fList[0]);
        } else {
            // load file to server
            await loadMedia(
                fList[0],
                (res) => {
                    if (setFileAfterSave) {
                        setDocument({
                            id: res?.id,
                            title: fList[0]?.name
                        });
                        setFileAfterSave(fList[0]);
                        if (setFileData) setFileData(fList[0]);
                    } else {
                        const parseName = res?.conversions?.original?.split('/');
                        const nameDoc = parseName[parseName?.length - 1];

                        setDocument({
                            title: nameDoc,
                            url: res?.conversions?.original,
                            id: res?.id
                        });
                    }
                },
                acceptStr,
                '1',
                'images',
                documentId,
                'document');
        }
    }

    const clearHandler = async () => {
        if (document?.id) {
            setLoad(true)
            await removeMedia(document?.id).then(res => {
                toast(res?.data?.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setDocument(null);
                if (setFileAfterSave) setFileAfterSave(null)
                if (setFileData) setFileData(null)
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() =>  setLoad(false));
        }
    };

    useEffect(() => {
        if (val) {
            setDocument(val);
        }
    }, [val]);

    return (
        <div className={className}>
            <div className={cn(s.wrap, {
                [s.sizeSm]: sm,
                [s.border]: border
            })}
            >

                <input
                    className={s.inputHidden}
                    type="text"
                    name={name}
                    ref={ref}
                    {...defVal && {defaultValue: defVal}}
                />

                <div
                    className={cn(s.imgBox,
                        // {[s.imgBoxError]: !!formH.errors[name]}
                        {
                            // [s.imgBoxError]: !!get(formH.errors, name),
                            [s.img]: document
                        })}
                    style={(document && border) ? {padding: 0, border: 0, overflow: 'hidden'} : {}}
                >
                    <input
                        type="file"
                        onChange={fileHandler}
                        accept={accept}
                    />

                    {document
                        ? (
                            <div>
                                <img src={icon_document_pdf} alt="document pdf"/>
                                {document?.url
                                    ? <a href={document?.url} target="_blank" download>{document?.title}</a>
                                    : <span>{document?.title}</span>}
                            </div>
                        )
                        : (
                            <>
                                <i className="icon icon-download"/>
                                <div>{placeHolder ? placeHolder : 'Завантажити файл'}</div>
                            </>
                        )}
                    {removeBtn && document && (
                        <button
                            className={s.remove}
                            type="button"
                            onClick={clearHandler}
                        >
                            <img src={icon_delete} alt="icon delete" />
                        </button>
                    )}
                </div>
            </div>
            {load && <Loader />}
        </div>
    );
});

export default InputFileDocument;
