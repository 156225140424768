import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import s from './Loader.module.scss';

const Loader = ({ attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn(s.wrap, attrs && attrs.className),
  };

  const [scrollHeight, setScrollHeight] = useState('100%');
  const [offsetHeight, setOffsetHeight] = useState('300px');

  useEffect(() => {
    let maxScroll = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );

    if (maxScroll > 1000) {
      if (attrs?.style?.height) {
        const parseHeight = attrs?.style?.height.split('px')
        maxScroll = +parseHeight[0]
      } else maxScroll = 700
    }

    setScrollHeight(`${maxScroll}px`);
  }, []);

  useEffect(() => {
    setOffsetHeight(`${document.body.clientHeight / 2}px`);
  }, []);

  return (
    <div {...attrsMerge} style={{ height: `${scrollHeight}` }}>
      <div className={cn('loading', s.loader)} style={{ top: `${offsetHeight}` }} />
    </div>
  );
};

export default Loader;
