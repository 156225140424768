import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import cn from 'classnames';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import errorMessage from "../../lib/errorMessage";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s from "./Selection.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import Input from "../../components/common/TableListViewNew/Input/Input";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import Button from "../../components/ui/Button";
import addIcon from "../../assets/icons/icon_add.svg";
import { getProduct } from "../../lib/api/products";
import InputFetchResult from "../../components/ui/InputFetchResultNew";
import rangeArrObjs from "../../lib/rangeArrObjs";
import {geDomainName} from "../../lib/const/domains";
import Modal from "./Modal/Modal";
import icon_edit from "../../assets/icons/icon_edit.svg";
import icon_delete from "../../assets/icons/icon_delete.svg";
import { removeSelectionCategory } from "../../lib/api/selections";

const SelectionEditView = () => {
  const { id: selectionID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: selectionID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/selection/not-found',
      remove: `/app/selections${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/selections${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Вибірки товарів створено',
      edit: 'Вибірки товарів оновлено',
      remove: 'Вибірки товарів видалено',
    },
  });

  const [activeModal, setActiveModal] = useState(false);
  const [catModal, setCatModal] = useState(null);

  const [tableList, setTableList] = useState([]);
  const [filterTable, setFilterTable] = useState([]);
  const [searchJde, setSearchJde] = useState('');

  const handleOnChangeFilter = (e) => setSearchJde(e.target.value);

  const clearFilter = () => {
    setFilterTable(tableList);
    setSearchJde("");
  };

  const [excludedProductIds, setExcludedProductIds] = useState([]);

  const [chooseProduct, setChooseProduct] = useState('');
  const [chooseDomain, setChooseDomain] = useState(null);
  const [chooseCategory, setChooseCategory] = useState(null);

  const addProducts = async () => {
    await getProduct({
      jde: chooseProduct,
      per_page: 10000
    })
        .then((res) => {
          if (!!res?.data?.data?.length) {
            const parseArray = res?.data?.data?.map(item => ({
              id: item?.id,
              jde: item?.jde,
              title: item?.title_ua
            }));

            if (!!tableList?.length) {
              setTableList(prev => {
                const mergedArray = prev.concat(parseArray);

                return mergedArray.filter((item, index, self) =>
                    index === self.findIndex((t) => t.jde === item.jde)
                );
              })
            } else {
              setTableList(parseArray);
            }
          }

        })
        .catch((err) => errorMessage(err, ''))
        .finally(() => setChooseProduct(''));
  }

  useEffect(() => {
    if (!!data?.fields?.products?.length) {
      setTableList((prev) => {
        if (!!prev?.length) {
          return [...prev, ...data?.fields?.products];
        } else {
          return [...data?.fields?.products];
        }
      })
    }
  }, [data?.fields?.products]);

  useEffect(() => {
    if (!!data?.fields?.categories?.length) {
      setTableList((prev) => {
        if (!!prev?.length) {
          return [...prev, ...data?.fields?.categories];
        } else {
          return [...data?.fields?.categories];
        }
      })
    }
  }, [data?.fields?.category_ids]);

  useEffect(() => {
    if (!!data?.fields?.excluded_product_ids?.length) {
      setExcludedProductIds(data?.fields?.excluded_product_ids)
    }
  }, [data?.fields?.excluded_product_ids]);

  useEffect(() => {
    if (!!tableList?.length) {
      setFilterTable(tableList);
    } else {
      setFilterTable([]);
    }
  }, [tableList]);

  useEffect(() => {
    if (!!searchJde?.length && !!tableList?.length) {
      setFilterTable(tableList?.filter((item => item?.jde?.includes(searchJde))));
    } else {
      setFilterTable(tableList);
    }
  }, [searchJde]);

  /* eslint-disable */
  return (
      <>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Selection edit`} />
        <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0} }} >

          <button type="submit" className="d-hide" />

          <TopPanelChild
              title={selectionID ? data && data?.fields?.title : "Нова вибірка товарів"}
              breadcrumbs={{
                items: [
                  {
                    title: "Головна",
                    url: "/app",
                  },
                  {
                    title: "Вибірки товарів",
                    url: `/app/selections${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  },
                  {
                    title: selectionID ? data && data?.fields?.title: "Нова вибірка товарів"
                  },
                ],
              }}
              back={`/app/selections${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
              id={selectionID}
              children={<>
                {/* is_active */}
                <CheckboxSwitch
                    name="is_active"
                    id="col-is-active"
                    label="Активність"
                    formH={formH}
                    defaultChecked={data?.fields?.is_active}
                />
              </>}
              btnEdit
          />

          {!load ? (
              <div className={s.wrapper}>
                <div className={s.wrapper__top}>
                  {/* title */}
                  <Group attrs={{ style: { margin: 0, maxWidth: '100%' } }}>
                    <ColFull attrs={{ style: { padding: 0 } }}>
                      <InputWrapper
                          label="Назва вибірки"
                          id="col-title"
                          errorComponent={errors?.title &&
                          <p className="form-input-hint">{errors?.title?.message}</p>}
                          style={{ margin: 0 }}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.title,
                            })}
                            type="text"
                            placeholder="Назва вибірки"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="title"
                            id="col-title"
                            defaultValue={data?.fields?.title}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>
                </div>
                <div className={s.left}>
                  <div className={s.left__item}>
                    <div className={s.left__text}>Додати товар</div>

                    <Group attrs={{ style: { margin: 0, maxWidth: '100%' } }}>
                      <ColFull attrs={{ style: { padding: 0 } }}>
                        <InputWrapper
                            label="JDE"
                            id="col-product-id"
                            style={{ margin: 0 }}
                        >
                          <input
                              className={s_Input.input}
                              type="text"
                              placeholder="JDE"
                              name="product_id"
                              id="col-product-id"
                              onChange={(e) => setChooseProduct(e.target.value)}
                              value={chooseProduct}
                          />
                        </InputWrapper>
                      </ColFull>
                    </Group>

                    <Button onClick={addProducts} purple disabled={!chooseProduct?.length}>
                      <img src={addIcon} alt="add product" />
                      Додати
                    </Button>
                  </div>
                  <div className={s.left__item}>
                    <div className={s.left__text}>Додати категорію</div>

                    {/* domains */}
                    <Group attrs={{ style: { margin: 0 } }}>
                      <ColFull attrs={{ style: { padding: 0 } }}>
                        <div className={s_Select.select__inner} style={{ margin: 0 }}>
                          <div className={s_Select.select__wrapper}>
                            {data
                                ? (
                                    <select
                                        name="domains"
                                        className={s_Select.select}
                                        id="col-domain"
                                        onChange={(e) => {
                                          if (e.target.value) {
                                            setChooseDomain(+e.target.value)
                                          } else {
                                            setChooseDomain(null)
                                          }
                                        }}
                                    >
                                      <option value="">виберіть домен</option>
                                      {data && !!data.domains && data.domains.map((domain) => (
                                          <option key={domain.id} value={domain.id}>
                                            {domain.title}
                                          </option>
                                      ))}
                                    </select>
                                )
                                : (
                                    <div className="p-relative">
                                      <div className="loading" />
                                    </div>
                                )}
                            <label className={s_Select.label} htmlFor="col-domain">Домен</label>
                          </div>
                        </div>
                      </ColFull>
                    </Group>

                    {/* categories */}
                    <Group attrs={{ style: { margin: 0 } }}>
                      <ColFull attrs={{ style: { padding: 0 } }}>
                        <div className={s_Select.select__inner} style={{ margin: 0 }}>
                          <div className={s_Select.select__wrapper}>
                            {data
                                ? (
                                    <select
                                        name="categories"
                                        className={s_Select.select}
                                        id="col-categories"
                                        onChange={(e) => {
                                          if (e.target.value) {
                                            setChooseCategory(+e.target.value)
                                          } else {
                                            setChooseCategory(null)
                                          }
                                        }}
                                    >
                                      <option value="">виберіть категорію</option>
                                      {data && !!data?.categories && rangeArrObjs(data?.categories, 'children', (e, i, iArr, depth) => {
                                        const nest = new Array(depth).join('--');

                                        if (chooseDomain) {
                                          if (e?.domain_id !== chooseDomain) return null;
                                        }
                                        return (
                                            <option
                                                key={iArr.join('-')}
                                                value={e.id}
                                            >
                                              {`${nest} ${e.title}`}
                                            </option>
                                        );
                                      })}
                                    </select>
                                )
                                : (
                                    <div className="p-relative">
                                      <div className="loading" />
                                    </div>
                                )}
                            <label className={s_Select.label} htmlFor="col-categories">Категорія</label>
                          </div>
                        </div>
                      </ColFull>
                    </Group>

                    <Button
                        onClick={() => {
                          if (!!data?.categories?.length) {
                            const parseCat = rangeArrObjs(data?.categories, 'children', (e, i, iArr, depth) => ({
                              title: e?.title,
                              domain_id: e?.domain_id,
                              id: e?.id
                            }));

                            const findCat = parseCat?.find(item => item.id === chooseCategory);
                            if (findCat) {
                              if (!!tableList?.length) {

                                setTableList(prev => {
                                  const temp = [...prev];

                                  const findDublicate = temp?.find(item => item.id === findCat?.id);

                                  if (findDublicate) {
                                    return temp;
                                  }

                                  return [...prev, findCat]
                                })
                              } else {
                                setTableList([findCat]);
                              }
                              setChooseCategory(null);
                            }
                          }

                        }}
                        disabled={chooseCategory === null}
                        purple
                    >
                      <img src={addIcon} alt="add category" />
                      Додати
                    </Button>
                  </div>
                </div>
                <div className={s.table_inner}>
                  <div className={s.table_wrapper}>
                    <TableListViewTable>
                      <thead>
                      <tr>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                          ID
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                          <div>
                            <span style={{ whiteSpace: 'nowrap' }}>JDE</span>
                            <Input
                                value={searchJde}
                                onChange={handleOnChangeFilter}
                                placeholder="Пошук"
                                funcClearValue={clearFilter}
                                style={{ maxWidth: "100%", width: '100%' }}
                                styleWrapper={{ width: '100%' }}
                            />
                          </div>
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                          Назва товару / Категорія
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                          Домен
                        </th>
                        <th />
                      </tr>
                      </thead>
                      <tbody>
                      { !!filterTable?.length
                          ? filterTable?.map((e, i) => (
                              <TableListViewRow
                                  id={e?.id}
                                  key={`table-selection-item-${e?.id}-${i}`}
                                  controlsOff
                              >
                                <td>{e?.id}</td>
                                <td>{e?.jde ? e?.jde : '---'}</td>
                                <td>
                                  {e?.domain_id
                                      ? e?.title ? (
                                          <div style={{ justifyContent: 'flex-start' }}>
                                            <div className={s.title_cat}>{e?.title}</div>
                                            <button
                                                className={cn(s.btn_cat, {
                                                  [s.btn_cat__green]: !e?.excluded_products_count,
                                                  [s.btn_cat__red]: !!e?.excluded_products_count
                                                })}
                                                type="button"
                                            >
                                              {e?.excluded_products_count || 0}
                                            </button>
                                          </div>
                                      )
                                      : '---'
                                      : e?.title ? e?.title : '---'}
                                </td>
                                <td>
                                  {e?.domain_id
                                      ? geDomainName(e?.domain_id)
                                          ? geDomainName(e?.domain_id)?.title
                                          : e?.domain_id
                                      : '---'}
                                </td>
                                <td style={{ width: '75px' }}>
                                  <div className="table-controls">
                                    {e?.domain_id && (
                                        <div
                                            className="mr-2"
                                            onClick={() => {
                                              setActiveModal(prev => !prev);
                                              setCatModal(e);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                          <img src={icon_edit} style={{ maxWidth: 'max-content' }} alt="Icon edit" />
                                        </div>
                                    )}
                                    <button
                                        type="button"
                                        className={s.btn_danger}
                                        onClick={async () => {
                                          if (selectionID && e?.domain_id) {
                                            await removeSelectionCategory(selectionID, e?.id).then((res) => {
                                              if (res?.data?.data) {
                                                setExcludedProductIds(res?.data?.data?.excluded_product_ids);
                                              }
                                            }).catch((err) => errorMessage(err, ''))
                                          }

                                          setTableList((prev) => {
                                            const temp = [...prev];
                                            temp.splice(i, 1);

                                            setFilterTable(temp);
                                            return temp;
                                          })
                                        }}
                                    >
                                      <img src={icon_delete} alt="icon delete" />
                                    </button>
                                  </div>
                                </td>
                              </TableListViewRow>
                          ))
                          : (
                              <TableListViewRow borderNone disabled>
                                <td colSpan={4} style={{ paddingTop: '22px' }}>Немає товарів або категорій</td>
                              </TableListViewRow>
                          )}
                      </tbody>
                    </TableListViewTable>
                  </div>

                  {!!tableList?.length && tableList?.filter(item => item?.domain_id)?.map((item, i) => (
                      <input
                          type="hidden"
                          ref={formH.register}
                          name={`category_ids[${i}]`}
                          value={item?.id}
                      />
                  ))}

                  {!!tableList?.length && tableList?.filter(item => item?.jde)?.map((item, i) => (
                      <input
                          type="hidden"
                          ref={formH.register}
                          name={`product_ids[${i}]`}
                          value={item?.id}
                      />
                  ))}
                </div>
              </div>
          ) : <Loader />}

          {!!excludedProductIds?.length ? excludedProductIds?.map((item, index) => (
              <input
                  name={`excluded_product_ids[${index}]`}
                  value={item}
                  type="hidden"
                  ref={formH.register}
              />
          )) : <input
              name={`excluded_product_ids[]`}
              value={null}
              type="hidden"
              ref={formH.register}
          />}
        </Form>

        {activeModal && <Modal
            active={activeModal}
            setActive={setActiveModal}
            category={catModal}
            excludedProductIds={excludedProductIds}
            setExcludedProductIds={setExcludedProductIds}
            title={`Виключити з категорії  “${catModal?.title}”`}
            setTableList={setTableList}
        />}
      </>
  )
};

export default SelectionEditView;
