import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cn from "classnames";
import s from "../../SpecOfferEdit.module.scss";
import sSelect from "../../../OrderEditView/fakeData/orderStyle.module.scss";
import parseCategoriesActionNodes from "../../../../lib/helpers/parseCategoriesActionNodes";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import {INPUT_MAX_LENGTH} from "../../../../const/view";
import {closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import TableListViewTable from "../../../../components/common/TableListViewNew/Table";
import TableHead from "../../../../components/common/TableListViewNew/TableHead";
import TableListViewFiltersRow from "../../../../components/common/TableListViewNew/FiltersRow";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import TableListViewRow from "../../../../components/ui/DragComponents/Row";
import {getProduct, getProductByID} from "../../../../lib/api/products";
import {toast} from "react-toastify";
import errorMessage from "../../../../lib/errorMessage";
import Loader from "../../../../components/ui/Loader";
import {refreshToken} from "../../../../lib/crud/refreshToken";
import icon_close_red from "../../../../assets/icons/icon_close_red.svg";
import {specMoveTo, specOfferCalculate} from "../../../../lib/api/specOffers";
import icon_add from "../../../../assets/icons/icon_add.svg";
import InputWrapper from "../../../../components/ui/InputWrapper";
import useList from "../../../../lib/crud/useList";
import {useLocation, useNavigate} from "react-router-dom";
import {parseDataSend} from "../../api";

const ProductBlockNew = ({
        activeTab,
        name,
        sendName,
        domainWatch,
        data,
        formH,
        discountPercent,
        discountValue,
        showActive,
        priceOrder = false,
    }) => {
        const [sortArray, setSortArray] = useState([1]);
        const [meta, setMeta] = useState(null);
        const [activeRowId, setActiveRowId] = useState(null);
        const [filterProductsList, setFilterProductsList] = useState([]);
        const [productsAList, setProductsAList] = useState([]);
        const [categories, setCategories] = useState([]);
        const [activeCategory, setActiveCategory] = useState(0);
        const [load, setLoad] = useState(false);
        const [addProductA, setAddProductA] = useState('');
        const [block, setBlock] = useState(false);
        const [additionalFilter, setAdditionalFilter] = useState([]);
        const [inputPrice, setInputPrice] = useState([]);

        const location = useLocation();
        const navigate = useNavigate();

        const {
            urlParams,
        } = useList({
            location,
            navigate,
        });

        const categoriesList = useMemo(() => {
            return data?.categories?.filter(item => item.domain_id.toString() === domainWatch?.toString());
        }, [data, domainWatch]);


        const sensors = useSensors(
            useSensor(MouseSensor, {}),
            useSensor(TouchSensor, {}),
            useSensor(KeyboardSensor, {})
        );

        const handleDragStart = (event) => {
            setActiveRowId(event.active.id);
        }

        const pushUrlParams = (info) => {
            const params = new URLSearchParams(window.location.search);

            if (info?.jde){
                params.set('jde', info.jde);
            } else {
                params.delete('jde');
            }

            if (info?.title){
                params.set('title', info.title);
            } else {
                params.delete('title');
            }

            navigate(`?${params.toString()}`, {replace: true});

            const res = filterProductsList.filter(product => {
                const matchesJde = info.jde ? product.jde === info.jde : true;
                const matchesTitle = info.title ? product.title.includes(info.title) : true;
                return matchesJde && matchesTitle;
            });

            setAdditionalFilter(res.length ? res : [true]);

            setMeta({
                total: filterProductsList.length,
            });

            const totalArray = filterProductsList.map(product => product.position);
            setSortArray(totalArray);

            setBlock(true)
        };

        const itemsIds = useMemo(() => productsAList?.map(({id}) => id), [productsAList]);
        const handleDragEnd = async(event) => {
            const {active, over} = event;
            if (block){
                let err = {response: {status: 'customDocumentSelect'}}
                await errorMessage(err, 'Збережіть результат розрахунку щоб продовжити сортування')
            } else if (active.id !== over.id){
                const newIndex = itemsIds.indexOf(over.id);

                await specMoveTo(data?.fields?.id, active.id, newIndex + 1, {
                    type: sendName + 's'
                }).then(res => {
                    if (res?.data){
                        setProductsAList(res?.data?.data)
                        setMeta(res?.data?.meta)
                    }

                    toast('Позиція оновлена', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).catch(async(err) => await errorMessage(err, ''));
            }
            setActiveRowId(null);
        }

        const handleDragCancel = () => {
            setActiveRowId(null);
        }

        const addByCategoryHandler = useCallback(async() => {
            if (activeCategory){
                setBlock(true);
                setLoad(true);
                await getProduct({
                    categories: activeCategory,
                    // per_page: +meta?.per_page
                }).then((res) => {
                    if (!!res?.data?.data?.length){
                        addAsyncProducts(res.data.data?.map((e, i) => ({...e, title: e?.title_ua || e?.title_ru, position: i + 1})));
                    } else {
                        toast(`Товаров ${activeCategory} не існує`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                    .catch(async(err) => await errorMessage(err, '/api/admin/product GET'))
                    .finally(() => setLoad(false))
            }
        }, [activeCategory]);

        const addAsyncProducts = (dataAsync) => {
            setProductsAList(prev => {
                const temp = [...prev];
                dataAsync.forEach(item => {
                    const findProduct = temp.findIndex(tempItem => tempItem.jde === item?.jde);
                    if (findProduct === -1){
                        temp.push(item);
                    }
                })

                let totalArray = []
                if (!!temp?.length){
                    temp?.forEach((item, i) => {
                        totalArray.push(i + 1)
                    })
                }

                setSortArray(totalArray);

                return temp?.map((e, i) => ({...e, position: i + 1}));
            })


        }

        const removeAItem = (el) => {
            const temp = filterProductsList.filter(item => item.id !== el.id);
            setFilterProductsList(temp);
        }

        const removeAllProduct = useCallback(() => {
            setProductsAList([]);
            setFilterProductsList([]);
        }, [productsAList, filterProductsList]);

        const handleAddProductA = useCallback(async() => {
            if (!!addProductA.length){
                await getProduct({
                    jde: addProductA?.split(' '),
                    // per_page: +meta?.per_page
                }).then((res) => {
                    if (!!res?.data?.data?.length){
                        setBlock(true);
                        setProductsAList(prev => {
                            if (!!prev?.length){
                                const temp = [...prev];

                                res?.data?.data?.forEach((item) => {
                                    const findProduct = temp.findIndex(tempItem => tempItem.jde === item?.jde);
                                    if (findProduct === -1){
                                        temp.push(item);
                                    }
                                });

                                return temp?.map((e, i) => ({...e, title: e?.title || e?.title_ua || e?.title_ru, position: i + 1}));
                            } else {
                                return res?.data?.data?.map((e, i) => ({...e, title: e?.title || e?.title_ua || e?.title_ru, position: i + 1}));
                            }
                        })

                        setSortArray(prev => {
                            if (!!prev?.length){
                                const temp = [...prev];
                                temp.push(temp.length + 1)
                                return temp
                            } else {
                                return 1;
                            }
                        });
                    } else {
                        toast(`Товару не ${addProductA} існує`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                    setAddProductA('');
                })
                    .catch(async(err) => {
                        if (err?.response?.status === 401){
                            await refreshToken(async() => {
                                await getProduct({jde: addProductA}).then((res) => {
                                    if (!!res?.data?.data?.length){
                                        setProductsAList(prev => {
                                            if (!!prev?.length){
                                                const temp = [...prev];

                                                res?.data?.data?.forEach((item) => {
                                                    const findProduct = temp.findIndex(tempItem => tempItem.jde === item?.jde);
                                                    if (findProduct === -1){
                                                        temp.push(item);
                                                    }
                                                });

                                                return temp?.map((e, i) => ({...e, title: e?.title || e?.title_ua || e?.title_ru, position: i + 1}));
                                            } else {
                                                return res?.data?.data?.map((e, i) => ({
                                                    ...e,
                                                    title: e?.title || e?.title_ua || e?.title_ru,
                                                    position: i + 1
                                                }));
                                            }

                                        })
                                    } else {
                                        toast(`Товару не ${addProductA} існує`, {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                    }

                                    setAddProductA('');
                                })
                            })
                        } else {
                            await errorMessage(err, '/api/admin/product GET')
                        }
                    })
                    .finally(() => setLoad(false))
            }
        }, [productsAList, addProductA])

        const productsList = useMemo(() => {
            return productsAList;
        }, [productsAList]);

        const calculateProducts = async() => {
            const parseProducts = productsList?.map(product => product?.product_id);
            const dataSend = {
                domain_id: domainWatch,
                discount_value: discountValue || null,
                discount_percent: discountPercent || null,
                products: parseProducts
            }

            if (parseProducts.some(product => typeof product === 'undefined')) {
                toast.warning('Збережіть спеціальну пропозицію для розрахунку', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            } else {
                await specOfferCalculate(dataSend).then((res) => {
                    if (res?.data){
                        setFilterProductsList((prev) => {
                            const temp = [...prev];

                            res?.data?.forEach(item => {
                                const findProduct = temp?.findIndex(prodTemp => prodTemp.product_id === item?.product_id);
                                if (findProduct !== -1){
                                    if (temp?.[findProduct]){
                                        temp[findProduct].price_cart = item.price_full;
                                        temp[findProduct].price_order = item.price_discount;
                                    } else {
                                        temp[findProduct] = {
                                            price_cart: item.price_full,
                                            price_order: item.price_discount
                                        };
                                    }

                                }
                            });

                            return temp;
                        })
                    }
                }).catch(err => errorMessage(err, '/api/admin/special-offer/calculate-discount POST'))
            }
        }

        const handleChangePriceA = useCallback((e, productId) => {
            const newPriceOrder = e.target.value;

            setFilterProductsList(prev => {
                if (prev?.length){
                    const temp = [...prev];
                    const findIndex = temp.findIndex(item => +item.id === +productId);

                    if (findIndex !== -1){
                        temp[findIndex] = {
                            ...temp[findIndex],
                            price_order: newPriceOrder
                        };
                    }

                    return temp;
                }
                return prev;
            });

            setBlock(true)
        }, [productsAList]);

        useEffect(() => {
            setCategories(parseCategoriesActionNodes(categoriesList))
        }, [categoriesList, domainWatch]);

        useEffect(() => {
            if (data?.fields?.id) {
                const getProducts = async () => {
                    const res = await getProductByID(data.fields.id, {
                        type: sendName + 's'
                    })

                    if (res?.data?.data?.length){
                        setProductsAList(res?.data?.data);
                    }

                    if (res?.data?.meta){
                        setMeta(res?.data?.meta);
                    }

                    if (res?.data?.total){
                        const totalArray = [...Array(res?.data?.total + 1).keys()].filter(item => !!item);
                        if (!!res?.data?.data?.length){
                            res?.data?.data?.forEach(item => {
                                if (!totalArray?.includes(item?.position)){
                                    totalArray.push(item?.position)
                                }
                            })
                        }
                        setSortArray(totalArray);
                    }
                }

                getProducts()

                const params = new URLSearchParams();
                navigate(`?${params.toString()}`, {replace: true})
            }
        }, [data, activeTab]);

        useEffect(() => {
            if (!!productsAList?.length){
                setFilterProductsList(productsAList);
            }
        }, [productsAList]);

        const newPositionHandler = (e, value) => {
            setFilterProductsList(prev => {
                if (!!prev?.length){
                    const temp = [...prev];
                    const findIndex = temp.findIndex(item => +item.id === +e.id);
                    temp[findIndex] = {
                        ...temp[findIndex],
                        position: value
                    }

                    return temp
                }
            })
        }

        const handleSelectSort = async(e, value) => {
            if (block){
                let err = {response: {status: 'customDocumentSelect'}}
                await errorMessage(err, 'Збережіть результат розрахунку щоб продовжити сортування')
            } else {
                await specMoveTo(data?.fields?.id, e.id, value, {
                    type: sendName + 's',
                }).then(res => {
                    if (res?.data){
                        setProductsAList(res?.data?.data)
                        setMeta(res?.data?.meta)
                    }

                    toast('Позиція оновлена', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).catch(async(err) => await errorMessage(err, ''));
            }
        }

        useEffect(() => {
            if (meta?.total){
                const totalArray = [...Array(meta?.total + 1).keys()].filter(item => !!item);
                if (!!meta?.length){
                    meta?.forEach(item => {
                        if (!totalArray?.includes(item?.position)){
                            totalArray.push(item?.position)
                        }
                    })
                }
                setSortArray(totalArray);
            }
        }, [meta?.total, meta]);

        return (
            <div className={cn({
                [s.overflowHidden]: activeTab !== showActive
            })}>
                <div style={{display: "flex", gap: "20px", width: "100%", margin: "10px 0"}}>
                    <div className={s.tabs__search} style={{height: "60px"}}>
                        <div className={sSelect.select__wrapper}>
                            <select
                                name="category_id"
                                className={sSelect.select}
                                id="filter-product-category-id"
                                defaultValue={activeCategory}
                                onChange={(e) => setActiveCategory(e?.target.value)}
                                style={{padding: "4px 16px"}}
                            >
                                <option value="" disabled>Категорія</option>
                                {categories?.map((e, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={e.id}
                                        >
                                            {e.title}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className={s.buttonWrapper} onClick={() => addByCategoryHandler()}>
                            <img src={icon_add} alt="icon add green"/>
                            Додати
                        </div>
                    </div>
                    <div className={s.tabs__search}>
                        <div style={{width: "100%", height: "60px"}}>
                            <InputWrapper
                                label="JDE / Назва товару"
                                id="JDE-name-input"
                                style={{width: "100%"}}
                                customStyle={{width: "100%"}}
                            >
                                <input
                                    className={cn(s_Input.input)}
                                    type="text"
                                    placeholder="JDE / Назва товару"
                                    name="JDE-name-input"
                                    id="so-JDE-name-input"
                                    value={addProductA}
                                    onChange={(e) => setAddProductA(e?.target?.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && handleAddProductA()}
                                />
                            </InputWrapper>
                        </div>

                        <div className={s.buttonWrapper} onClick={() => handleAddProductA()} tabIndex={0}>
                            <img src={icon_add} alt="icon add green"/>
                            Додати
                        </div>
                    </div>
                </div>
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    onDragCancel={handleDragCancel}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <TableListViewTable>
                        <TableHead
                            showAction={false}
                            arrayChildren={priceOrder ? ['', 'Назва товару', 'JDE', 'Позиція', {
                                title: 'Замовлення',
                                attr: {width: '150px'}
                            }, ''] : ['', 'Назва товару', 'JDE', 'Позиція', '']}
                            filterChildren={<TableListViewFiltersRow
                                urlParams={urlParams}
                                discountHandler={() => calculateProducts()}
                                onTextClickHandler={() => removeAllProduct()}
                                fields={priceOrder ? [
                                    {},
                                    {type: 'text', name: 'title', attr: {style: {width: '100%'}, placeholder: "Пошук"}},
                                    {type: 'text', name: 'jde', attr: {style: {width: '100%'}, placeholder: "Пошук"}},
                                    {},
                                    {type: 'calcOrder'},
                                    {
                                        type: 'textDivWithFunc',
                                        wrapperStyle: {color: "red", width: "40px", cursor: "pointer"},
                                        attrWrapper: {style: {textAlign: "center"}},
                                        text: "Очистити"
                                    },
                                ] : [
                                    {},
                                    {type: 'text', name: 'title', attr: {style: {width: '100%'}, placeholder: "Пошук"}},
                                    {type: 'text', name: 'jde', attr: {style: {width: '100%'}, placeholder: "Пошук"}},
                                    {},
                                    {
                                        type: 'textDivWithFunc',
                                        wrapperStyle: {color: "red", width: "40px", cursor: "pointer"},
                                        attrWrapper: {style: {textAlign: "center"}},
                                        text: "Очистити"
                                    },
                                ]}
                                onSubmit={pushUrlParams}
                            />}
                        />
                        <tbody style={additionalFilter?.length ? {display: "none"} : {}}>
                        {(!!filterProductsList && (
                            filterProductsList?.length
                                ? <SortableContext items={filterProductsList} strategy={verticalListSortingStrategy}>
                                    {filterProductsList?.map((e, i) => (
                                        <TableListViewRow
                                            id={e.id}
                                            key={`${sendName}[${i}]`}
                                            controlsOff={true}
                                            second={true}
                                        >
                                            <td>
                                                <span>{e?.title || e?.title_ua || '---'}</span>
                                            </td>
                                            <td>
                                                <span>{e?.jde || '---'}</span>
                                            </td>
                                            <td>
                                                <input
                                                    style={{background: "none", height: "20px", borderRadius: "6px", fontWeight: "600",
                                                        fontSize: "12px"}}
                                                    className="form-select"
                                                    type="text"
                                                    onChange={(event) => {
                                                        const newValue = event.target.value;
                                                        newPositionHandler(e, newValue);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter'){
                                                            handleSelectSort(e, event.target.value);
                                                        }
                                                    }}
                                                    value={e?.position}
                                                />
                                            </td>
                                            {priceOrder &&
                                                <td>
                                                    <input
                                                        className={s.listA__input}
                                                        style={{width: '100%'}}
                                                        type="number"
                                                        value={+e?.price_order || ''}
                                                        name={`${sendName}[${i}].price_order`}
                                                        ref={formH.register}
                                                        onChange={(i) => handleChangePriceA(i, e?.id)}
                                                    />
                                                </td>
                                            }
                                            <td>
                                                <button type="button" style={{
                                                    backgroundColor: "transparent", borderColor: "transparent",
                                                    cursor: "pointer", paddingLeft: "20px",
                                                }} onClick={() => removeAItem(e)}>
                                                    <img src={icon_close_red} alt="icon delete"/>
                                                </button>
                                            </td>
                                            <input
                                                type="hidden"
                                                value={e?.brand_id ? e?.id : e?.product_id}
                                                name={`${sendName}[${i}].product_id`}
                                                ref={formH.register}
                                            />
                                        </TableListViewRow>
                                    ))}
                                </SortableContext>
                                : (
                                    <TableListViewRow controlsOff={true} disabled>
                                        <td colSpan={4} style={{paddingTop: '22px'}}>Немає товарів</td>
                                    </TableListViewRow>
                                )
                        ))}
                        </tbody>
                        <tbody style={!additionalFilter?.length ? {display: "none"} : {}}>
                        {(!!additionalFilter && (
                            additionalFilter?.length && additionalFilter[0] !== true
                                ? <SortableContext items={additionalFilter} strategy={verticalListSortingStrategy}>
                                    {additionalFilter?.map((e, i) => (
                                        <TableListViewRow
                                            id={e.id}
                                            key={`${sendName}[${i}]`}
                                            controlsOff={true}
                                            second={true}
                                        >
                                            <td>
                                                <span>{e?.title || e?.title_ua || '---'}</span>
                                            </td>
                                            <td>
                                                <span>{e?.jde || '---'}</span>
                                            </td>
                                            <td>
                                                <input
                                                    style={{background: "none", height: "20px", borderRadius: "6px", fontWeight: "600",
                                                        fontSize: "12px"}}
                                                    className="form-select"
                                                    type="text"
                                                    onChange={(event) => {
                                                        const newValue = event.target.value;
                                                        newPositionHandler(e, newValue);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter'){
                                                            handleSelectSort(e, event.target.value);
                                                        }
                                                    }}
                                                    value={e?.position}
                                                />
                                            </td>
                                            {priceOrder &&
                                                <td>
                                                    <input
                                                        className={s.listA__input}
                                                        style={{width: '100%'}}
                                                        type="number"
                                                        value={+e?.price_order || ''}
                                                        name={`${sendName}[${i}].price_order`}
                                                        ref={formH.register}
                                                        onChange={(i) => handleChangePriceA(i, e?.id)}
                                                    />
                                                </td>
                                            }
                                            <td>
                                                <button type="button" style={{
                                                    backgroundColor: "transparent", borderColor: "transparent",
                                                    cursor: "pointer", paddingLeft: "20px",
                                                }} onClick={() => removeAItem(e)}>
                                                    <img src={icon_close_red} alt="icon delete"/>
                                                </button>
                                            </td>
                                        </TableListViewRow>
                                    ))}
                                </SortableContext>
                                : (
                                    <TableListViewRow controlsOff={true} disabled>
                                        <td colSpan={4} style={{paddingTop: '22px'}}>Немає товарів</td>
                                    </TableListViewRow>
                                )
                        ))}
                        </tbody>
                    </TableListViewTable>
                </DndContext>
                {load && <Loader/>}
            </div>
        );
    }
;

export default ProductBlockNew;