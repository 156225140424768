import React, { useEffect, useMemo, useState } from "react";

import cn from "classnames";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { VALIDATION } from "../../const/text";
import { INPUT_MAX_LENGTH } from "../../const/view";
import useEdit from "../../lib/crud/useEdit";
import Loader from "../../components/ui/Loader";
import Form from "../../components/ui/FormEditView/Form";
import Group from "../../components/ui/FormEditView/Group";
import {
    create, edit, getData, parseDataGet, parseDataSend, remove,
} from "./api";
import useDomainCategoriesProducts from "../../lib/hooks/useDomainCategoriesProducts";
import s_Main from "./SpecOfferBanner.module.scss";
import { Editor } from "@tinymce/tinymce-react";
import useTags from "../../lib/hooks/useTags";
import { DEVELOPMENT_ENVIRONMENT, TINY_API_KEY } from "../../const/api";
import getTZtoDate from "../../lib/getTZtoDate";
import { getMedia, removeMedia } from "../../lib/api/media";
import { ModalMedia } from "./Modal";
import ModalRemove from "../../components/ui/Modal/ModalRemove";
import { Helmet } from "react-helmet";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import errorMessage from "../../lib/errorMessage";
import CopyComponent from "../../components/ui/CopyComponent";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import InputDateNew from "../../components/ui/InputDateNew";
import s_OrderStyle from "../OrderEditView/fakeData/orderStyle.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import RSelect from "../../components/ui/RSelect";
import s from "../BannerManagementHomeEditView/BannerManagementHome.module.scss";
import ImageLoad from "../../components/ui/ImageLoad";
import CopySpecOfferBanner from "./component/CopySpecOfferBanner";

const SpecOfferBannerEditView = () => {
    const {id: bannerID} = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        watch,
        reset,
    } = formH;

    const {load, data, submitHandler} = useEdit({
        targetID: bannerID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/spec-offer-banners/not-found',
            remove: `/app/spec-offer-banners${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/spec-offer-banners${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Акцію створено',
            edit: 'Акцію оновлено',
            remove: 'Акцію видалено',
        },
    });

    const [descUaValue, setDescUaValue] = useState('');
    const [descRuValue, setDescRuValue] = useState('');

    const {action, state} = useDomainCategoriesProducts({
        defaultValues: data?.fields,
    });

    const {actionTag, stateTag} = useTags({
        defaultValues: data?.fields,
    });

    const [time, setTime] = useState({
        time_from: '',
        time_to: '',
    });

    const [date, setDate] = useState({
        date_from: '',
        date_to: '',
    });

    const [selectedSpecOffer, setSelectedSpecOffer] = useState([]);

    const [selectedTag, setSelectedTag] = useState([]);

    useEffect(() => {
        if (!!data?.fields?.special_offers?.length) {
            setSelectedSpecOffer(data?.fields?.special_offers);
        }
    }, [data?.fields?.special_offers]);

    useEffect(() => {
        if (!!data?.fields?.tags?.length) {
            setSelectedTag(data?.fields?.tags);
        }
    }, [data?.fields?.tags]);

    useEffect(() => {
        if (data?.fields?.date_from || data?.fields?.date_to) {
            const timeFrom = getTZtoDate(data?.fields?.date_from, true)?.split(' ')[1];
            const timeTo = getTZtoDate(data?.fields?.date_to, true)?.split(' ')[1];
            const dateTo = data?.fields?.date_to?.split(' ')[0];
            const dateFrom = data?.fields?.date_from?.split(' ')[0];
            setTime({
                time_from: `${timeFrom}:00`,
                time_to: `${timeTo}:00`,
            });
            setDate({
                date_from: dateFrom,
                date_to: dateTo,
            });
        } else {
            setTime({
                time_from: '00:00:00',
                time_to: '00:00:00',
            });
            setDate({
                date_from: '',
                date_to: '',
            });
        }

        if (!!data?.fields?.desc_ua?.length) {
            setDescUaValue(data?.fields?.desc_ua);
        }
        if (!!data?.fields?.desc_ru?.length) {
            setDescRuValue(data?.fields?.desc_ru);
        }
    }, [data?.fields]);

    const [activeMedia, setActiveMedia] = useState(false);
    const [activeRemove, setActiveRemove] = useState(false);
    const [typeCoverDataUa, setTypeCoverDataUa] = useState([]);
    const [typeCoverDataRu, setTypeCoverDataRu] = useState([]);
    const [typeMobileDataUa, setTypeMobileDataUa] = useState([]);
    const [typeMobileDataRu, setTypeMobileDataRu] = useState([]);
    const [domainId, setDomainId] = useState('1');

    const [dataModal, setDataModal] = useState({});

    const specOfferList = useMemo(() => {
        return !!data?.special_offers?.length
            ? data?.special_offers.filter(spec_offer => spec_offer.domain_id?.toString() === domainId?.toString())
            : []
    }, [domainId, data?.special_offers]);

    const domainUrl = useMemo(() => {
        if (!state?.domainsList?.length) {
            return null;
        }
        return state?.domainsList?.find(domainItem => domainItem?.id?.toString() === domainId?.toString());
    }, [domainId, state?.domainsList]);

    const urlSpecOffer = useMemo(() => {
        if (domainUrl) {
            return `${domainUrl?.domain}${domainUrl?.id === 1
                ? '/specialoffer/'
                : domainUrl?.id === 2
                    ? '/promotions/'
                    : '/specialoffer/'
            }${data?.fields?.url}.html`
        }
        return null;
    }, [domainUrl, data?.fields?.url]);

    useEffect(() => {
        if (bannerID) {
            getMedia({
                'entity_type': 'promo',
                'entity_id': bannerID,
                page: 1,
                per_page: 500,
            }).then(res => {
                const filterLang = (lang) => res?.data?.data?.filter(item => item?.custom_properties?.locale === lang);
                const filterLangRu = filterLang('ru');
                const filterCoverRu = filterLangRu?.filter(item => item?.collection_name === 'cover');

                if (filterCoverRu) {
                    setTypeCoverDataRu(filterCoverRu);
                }

                const filterMobileRu = filterLangRu?.filter(item => item?.collection_name === 'mobile');
                if (filterMobileRu) {
                    setTypeMobileDataRu(filterMobileRu);
                }

                const filterLangUa = filterLang('ua');
                const filterCoverUa = filterLangUa?.filter(item => item?.collection_name === 'cover');

                if (filterCoverUa) {
                    setTypeCoverDataUa(filterCoverUa);
                }

                const filterMobileUa = filterLangUa?.filter(item => item?.collection_name === 'mobile');
                if (filterMobileUa) {
                    setTypeMobileDataUa(filterMobileUa);
                }

            }).catch(async (err) => await errorMessage(err, 'Error /api/admin/media GET'))
        }

    }, [bannerID]);

    useEffect(() => {
        if (date?.date_from) {
            formH.clearErrors('date_from')
        } else {
            formH.setError('date_from', {type: 'text', message: "Поле не должно быть пустым"});
        }

        if (date?.date_to) {
            formH.clearErrors('date_to')
        } else {
            formH.setError('date_to', {type: 'text', message: "Поле не должно быть пустым"});
        }

        if (data?.fields?.domain_id) {
            setDomainId(data?.fields?.domain_id)
        }

    }, [date]);

    useEffect(() => {
        if (data?.fields?.domain_id) {
            setDomainId(data?.fields?.domain_id)
        }
    }, [data?.fields?.domain_id]);

    /* eslint-disable */
    return (
        <>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Spec offer banners edit`}/>

            <Form attrs={{onSubmit: handleSubmit(submitHandler)}}>

                <button type="submit" className="d-hide"/>

                <TopPanelChild
                    title={bannerID ? data?.fields?.title_ua && data?.fields?.title_ua : 'Нова акція'}
                    breadcrumbs={{
                        items: [
                            {
                                title: 'Головна',
                                url: '/app',
                                permissionName: 'admin.special-offer.banners.tag.index.index'
                            },
                            {
                                title: 'Акції',
                                url: `/app/spec-offer-banners${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                                permissionName: 'admin.special-offer.banners.tag.index.index'
                            },
                            {
                                title: bannerID ? data?.fields?.title_ua && data?.fields?.title_ua : 'Нова акція'
                            },
                        ],
                    }}
                    back={`/app/spec-offer-banners${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
                    id={bannerID}
                    children={(
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: (bannerID && urlSpecOffer) ? 'space-between' : 'flex-end', marginLeft: 16 }}>
                            {/* url */}
                            {bannerID && urlSpecOffer && (
                                <Group attrs={{ style: { margin: 0, padding: 0 } }}>
                                    <ColFull attrs={{ style: { margin: 0, padding: 0 } }}>
                                        <CopyComponent copyText={urlSpecOffer}>
                                            <a href={urlSpecOffer} target="_blank">{urlSpecOffer}</a>
                                        </CopyComponent>
                                    </ColFull>
                                </Group>
                            )}
                            <div>
                                <CheckboxSwitch
                                    name="is_active_on_card"
                                    id="b-is-active-on-card"
                                    label="Доступна за посиланням"
                                    formH={formH}
                                    defaultChecked={data?.fields?.is_active_on_card}
                                    secondType={true}
                                />
                                <CheckboxSwitch
                                    name="is_active"
                                    id="b-is-active"
                                    label="Активність"
                                    formH={formH}
                                    defaultChecked={data?.fields?.is_active}
                                />
                            </div>
                        </div>
                    )}
                    btnEdit
                />
                {data?.fields && (
                    <CopySpecOfferBanner data={data?.fields} />
                )}
                <div className={s_Main.top}>
                    <div className={s_Main.top__item}>
                        {/* is_active_on_main_page */}
                        {/*<Group attrs={{ style: { margin: 0 } }}>*/}
                        {/*    <ColFull attrs={{ style: { padding: 0 } }}>*/}
                        {/*        <CheckboxSwitch*/}
                        {/*            name="is_active_on_main_page"*/}
                        {/*            id="spec-offer-banner-is-active-on-main-page"*/}
                        {/*            label="Статус відображення на головній сторінці"*/}
                        {/*            formH={formH}*/}
                        {/*            defaultChecked={data?.fields?.is_active_on_main_page}*/}
                        {/*            fontWeight={400}*/}
                        {/*        />*/}
                        {/*    </ColFull>*/}
                        {/*</Group>*/}

                        {/* is_active_on_action_list */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <CheckboxSwitch
                                    name="is_active_on_action_list"
                                    id="spec-offer-banner-is-active-on-action-list"
                                    label="Статус відображення у списку акцій"
                                    formH={formH}
                                    defaultChecked={data?.fields?.is_active_on_action_list}
                                    fontWeight={400}
                                />
                            </ColFull>
                        </Group>

                        {/* sort */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Сортування"
                                    id="spec-offer-banner-sort"
                                    errorComponent={errors?.sort &&
                                    <p className="form-input-hint">{errors?.sort?.message}</p>}
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: formH.errors?.sort,
                                        })}
                                        type="number"
                                        placeholder="Сортування"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="sort"
                                        id="spec-offer-banner-sort"
                                        defaultValue={data?.fields?.sort}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* date_from */}
                        <div className={s_Main.date_block}>
                            <InputDateNew
                                name="date_from"
                                className="form-input"
                                id="so-date-from"
                                defVal={data?.fields?.date_from?.split(' ')?.[0]}
                                placeholder="Термін дії від"
                                formH={formH}
                                withZero={false}
                                min={data?.fields?.parent && data?.fields?.parent?.date_to?.split(' ')?.[0]}
                            />

                            <div className={s_Input.wrapper}>
                                <div className={s_Input.input__wrapper}>
                                    <input
                                        className={s_Input.input}
                                        type="time"
                                        name="time_from"
                                        id="so-time_from"
                                        placeholder="Час"
                                        ref={register}
                                        value={time.time_from}
                                        onChange={(e) => setTime((prev) => ({
                                            ...prev,
                                            time_from: `${e.target.value}:00`
                                        }))}
                                    />
                                    <label className={s_Input.label} htmlFor="so-time_from">Час</label>
                                </div>
                            </div>
                        </div>

                        {/* date_to */}
                        <div className={s_Main.date_block}>
                            <InputDateNew
                                name="date_to"
                                className="form-input"
                                id="so-date-to"
                                defVal={data?.fields?.date_to?.split(' ')?.[0]}
                                placeholder="Термін дії до"
                                formH={formH}
                                withZero={false}
                                min={data?.fields?.parent && data?.fields?.parent?.date_to?.split(' ')?.[0]}
                            />

                            <div className={s_Input.wrapper}>
                                <div className={s_Input.input__wrapper}>
                                    <input
                                        className={s_Input.input}
                                        type="time"
                                        name="time_to"
                                        id="so-time_to"
                                        defaultValue={data?.fields?.time_to?.split(' ')[0]}
                                        placeholder="Час"
                                        ref={register}
                                        value={time.time_to}
                                        onChange={(e) => setTime((prev) => ({
                                            ...prev,
                                            time_to: `${e.target.value}:00`
                                        }))}
                                    />
                                    <label className={s_Input.label} htmlFor="so-time_to">Час</label>
                                </div>
                            </div>
                        </div>

                        {/* domain_id */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <div className={s_OrderStyle.select__inner}>
                                    <div className={s_OrderStyle.select__wrapper}>
                                        <select
                                            name="domain_id"
                                            className={cn(s_OrderStyle.select, {
                                                [s_OrderStyle.select_error]: errors?.domain_id,
                                            })}
                                            id="spec-offer-banner-domain"
                                            value={domainId}
                                            onChange={(e) => setDomainId(e.target.value)}
                                            disabled
                                        >
                                            {!!state?.domainsList?.length && state?.domainsList?.map(domain => (
                                                <option
                                                    key={`spec-offer-banner-domain-item-${domain.id}`}
                                                    value={domain.id}
                                                >
                                                    {domain.title}
                                                </option>
                                            ))}
                                        </select>
                                        <label className={s_OrderStyle.label} htmlFor="spec-offer-banner-domain">Домен*</label>
                                    </div>
                                    {errors?.domain_id && <p className="form-input-hint">{errors.domain_id?.message}</p>}
                                </div>
                                <input type="hidden" name="domain_id" value={domainId} ref={register} />
                            </ColFull>
                        </Group>

                        {/* special_offers[] */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <div className={s_Main.sub_title}>Вибрати спеціальну пропозицію</div>
                                <RSelect
                                    props={{
                                        value: selectedSpecOffer,
                                        onChange: (opt) => {setSelectedSpecOffer(opt)},
                                        placeholder: 'Вибрати спеціальну пропозицію',
                                        options: specOfferList,
                                        isMulti: true,
                                        getOptionLabel: (opt) => opt.title_ua,
                                        getOptionValue: (opt) => opt.id
                                    }}
                                />

                                {!!selectedSpecOffer?.length && selectedSpecOffer?.map((item, index) => (
                                    <input
                                        key={`special-offer-item-${index}`}
                                        type="hidden"
                                        ref={formH.register}
                                        name={`special_offers_copy[${index}]`}
                                        value={item?.id}
                                    />
                                ))}
                            </ColFull>
                        </Group>

                        {/* tags[] */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <div className={s_Main.sub_title}>Фiльтр “Швидкий вибiр”</div>
                                <RSelect
                                    props={{
                                        value: selectedTag,
                                        onChange: (opt) => {setSelectedTag(opt)},
                                        placeholder: 'Фiльтр “Швидкий вибiр”',
                                        options: !!data?.tags?.length ? data?.tags : [],
                                        isMulti: true,
                                        getOptionLabel: (opt) => opt.title_ua,
                                        getOptionValue: (opt) => opt.id
                                    }}
                                />

                                {!!selectedTag?.length && selectedTag?.map((item, index) => (
                                    <input
                                        key={`tags-${index}`}
                                        type="hidden"
                                        ref={register}
                                        name={`tags[${index}]`}
                                        value={item.id}
                                    />
                                ))}
                            </ColFull>
                        </Group>
                    </div>
                    <div className={`${s_Main.top__item} ${s_Main.top__rigth}`}>
                        {/* title_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Назва пропозиції УКР"
                                    id="spec-offer-banner-title-ua"
                                    errorComponent={errors?.title_ua &&
                                    <p className="form-input-hint">{errors?.title_ua?.message}</p>}
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: formH.errors?.title_ua,
                                        })}
                                        type="text"
                                        placeholder="Назва пропозиції УКР"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="title_ua"
                                        id="spec-offer-banner-title-ua"
                                        defaultValue={data?.fields?.title_ua}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* name_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <div className={s_Select.textarea__wrapper}>
                                    <textarea
                                        name="name_ua"
                                        className={cn(s_Select.textarea, {
                                            [s_Select.select_error]: errors.name_ua,
                                        })}
                                        id="spec-offer-banner-name-ua"
                                        placeholder="Назва на сайті УКР"
                                        rows="4"
                                        ref={register({
                                            required: VALIDATION.req.required,
                                        })}
                                        defaultValue={data?.fields?.name_ua}
                                        maxLength={INPUT_MAX_LENGTH}
                                    />
                                    <label className={s_Select.label__textarea} htmlFor="spec-offer-banner-name-ua">
                                        Назва на сайті УКР
                                    </label>
                                </div>
                                {errors.name_ua && <p className="form-input-hint">{errors.name_ua.message}</p>}
                            </ColFull>
                        </Group>

                        {/* short_desc_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Опис анонсу УКР"
                                    id="spec-offer-banner-short-desc-ua"
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={s_Input.input}
                                        type="text"
                                        placeholder="Опис анонсу УКР"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register}
                                        name="short_desc_ua"
                                        id="spec-offer-banner-short-desc-ua"
                                        defaultValue={data?.fields?.short_desc_ua}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* validity_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Під період УКР"
                                    id="spec-offer-banner-validity-ua"
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={s_Input.input}
                                        type="text"
                                        placeholder="Під період УКР"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register}
                                        name="validity_ua"
                                        id="spec-offer-banner-validity-ua"
                                        defaultValue={data?.fields?.validity_ua}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* desc_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <Editor
                                    apiKey={TINY_API_KEY}
                                    value={descUaValue}
                                    onEditorChange={(newValue) => setDescUaValue(newValue)}
                                    init={{
                                        width: '100%',
                                        height: '300px',
                                        menubar: false,
                                        language: 'ru',
                                        toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist| preview | media customSlider',
                                        placeholder: 'Детальний опис УКР',
                                        plugins: ['link', 'code', 'lists', 'preview'],
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                                <input type="text" name='desc_ua' value={descUaValue} ref={register}
                                       style={{display: 'none'}} readOnly />
                            </ColFull>
                        </Group>

                        {bannerID && (
                            <div className={s.img_block}>
                                <div style={{ marginRight: 10 }}>
                                    <ImageLoad
                                        id={`product_img_mobile_ua`}
                                        nameParent={`product_images[image_list]`}
                                        formH={formH}
                                        accept="image/*"
                                        acceptStr="PNG, JPG, GIF"
                                        imageData={typeMobileDataUa}
                                        setImageData={setTypeMobileDataUa}
                                        setActiveMedia={setActiveMedia}
                                        setActiveRemove={setActiveRemove}
                                        setDataModal={setDataModal}
                                        prodID={bannerID}
                                        entityType='promo'
                                        collectionName='mobile'
                                        windowName="mobile / promo"
                                        langLabel="UA"
                                        width={160}
                                        height={170}
                                        altData={{
                                            alt_ua: '',
                                            locale: 'ua'
                                        }}
                                    />
                                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                                        490х480 px<br />
                                        (980х960 px)
                                    </div>
                                </div>
                                <div style={{ width: 'calc(100% - 152px)' }}>
                                    <ImageLoad
                                        id={`product_img_ua`}
                                        nameParent={`product_images[image_list]`}
                                        formH={formH}
                                        accept="image/*"
                                        acceptStr="PNG, JPG, GIF"
                                        imageData={typeCoverDataUa}
                                        setImageData={setTypeCoverDataUa}
                                        setActiveMedia={setActiveMedia}
                                        setActiveRemove={setActiveRemove}
                                        setDataModal={setDataModal}
                                        prodID={bannerID}
                                        entityType='promo'
                                        collectionName='cover'
                                        windowName='tablet / desktop'
                                        width={'100%'}
                                        langLabel="UA"
                                        altData={{
                                            alt_ua: '',
                                            locale: 'ua'
                                        }}
                                    />
                                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                                        1530x500 px<br />
                                        (3060x1000 px)
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={`${s_Main.top__item} ${s_Main.top__rigth}`}>
                        {/* title_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Назва пропозиції РУС"
                                    id="spec-offer-banner-title-ru"
                                    errorComponent={errors?.title_ru &&
                                    <p className="form-input-hint">{errors?.title_ru?.message}</p>}
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: formH.errors?.title_ru,
                                        })}
                                        type="text"
                                        placeholder="Назва пропозиції РУС"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="title_ru"
                                        id="spec-offer-banner-title-ru"
                                        defaultValue={data?.fields?.title_ru}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* name_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <div className={s_Select.textarea__wrapper}>
                                    <textarea
                                        name="name_ru"
                                        className={cn(s_Select.textarea, {
                                            [s_Select.select_error]: errors.name_ru,
                                        })}
                                        id="spec-offer-banner-name-ru"
                                        placeholder="Назва на сайті РУС"
                                        rows="4"
                                        ref={register({
                                            required: VALIDATION.req.required,
                                        })}
                                        defaultValue={data?.fields?.name_ru}
                                        maxLength={INPUT_MAX_LENGTH}
                                    />
                                    <label className={s_Select.label__textarea} htmlFor="spec-offer-banner-name-ru">
                                        Назва на сайті РУС
                                    </label>
                                </div>
                                {errors.name_ru && <p className="form-input-hint">{errors.name_ru.message}</p>}
                            </ColFull>
                        </Group>

                        {/* short_desc_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Опис анонсу РУС"
                                    id="spec-offer-banner-short-desc-ru"
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={s_Input.input}
                                        type="text"
                                        placeholder="Опис анонсу РУС"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register}
                                        name="short_desc_ru"
                                        id="spec-offer-banner-short-desc-ru"
                                        defaultValue={data?.fields?.short_desc_ru}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* validity_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Під період РУС"
                                    id="spec-offer-banner-validity-ru"
                                    style={{ margin: 0 }}
                                >
                                    <input
                                        className={s_Input.input}
                                        type="text"
                                        placeholder="Під період РУС"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={formH.register}
                                        name="validity_ru"
                                        id="spec-offer-banner-validity-ru"
                                        defaultValue={data?.fields?.validity_ru}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* desc_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <Editor
                                    apiKey={TINY_API_KEY}
                                    value={descRuValue}
                                    onEditorChange={(newValue) => setDescRuValue(newValue)}
                                    init={{
                                        width: '100%',
                                        height: '300px',
                                        menubar: false,
                                        language: 'ru',
                                        toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist| preview | media customSlider',
                                        placeholder: 'Детальний опис РУС',
                                        plugins: ['link', 'code', 'lists', 'preview'],
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                                <input type="text" name='desc_ru' value={descRuValue} ref={register}
                                       style={{display: 'none'}} readOnly/>
                            </ColFull>
                        </Group>

                        {bannerID && (
                            <div className={s.img_block}>
                                <div style={{ marginRight: 10 }}>
                                    <ImageLoad
                                        id={`product_img_mobile_ru`}
                                        nameParent={`product_images[image_list]`}
                                        formH={formH}
                                        accept="image/*"
                                        acceptStr="PNG, JPG, GIF"
                                        imageData={typeMobileDataRu}
                                        setImageData={setTypeMobileDataRu}
                                        setActiveMedia={setActiveMedia}
                                        setActiveRemove={setActiveRemove}
                                        setDataModal={setDataModal}
                                        prodID={bannerID}
                                        entityType='promo'
                                        collectionName='mobile'
                                        windowName="mobile / promo"
                                        langLabel="RU"
                                        width={160}
                                        height={170}
                                        altData={{
                                            alt_ua: '',
                                            locale: 'ru'
                                        }}
                                    />
                                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                                        490х480 px<br />
                                        (980х960 px)
                                    </div>
                                </div>
                                <div style={{ width: 'calc(100% - 152px)' }}>
                                    <ImageLoad
                                        id={`product_img_ru`}
                                        nameParent={`product_images[image_list]`}
                                        formH={formH}
                                        accept="image/*"
                                        acceptStr="PNG, JPG, GIF"
                                        imageData={typeCoverDataRu}
                                        setImageData={setTypeCoverDataRu}
                                        setActiveMedia={setActiveMedia}
                                        setActiveRemove={setActiveRemove}
                                        setDataModal={setDataModal}
                                        prodID={bannerID}
                                        entityType='promo'
                                        collectionName='cover'
                                        windowName='tablet / desktop'
                                        width={'100%'}
                                        langLabel="RU"
                                        altData={{
                                            alt_ua: '',
                                            locale: 'ru'
                                        }}
                                    />
                                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                                        1530x500 px<br />
                                        (3060x1000 px)
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {load && <Loader/>}

                {activeMedia && <ModalMedia
                    active={activeMedia}
                    setActive={setActiveMedia}
                    data={dataModal}
                    collectionName={dataModal?.collection_name === 'cover' ? 'cover' : 'mobile'}
                    locale={dataModal?.custom_properties?.locale === 'ru' ? 'ru' : 'ua'}
                    setImageData={dataModal?.custom_properties?.locale === 'ru'
                        ? dataModal?.collection_name === 'cover' ? setTypeCoverDataRu : setTypeMobileDataRu
                        : dataModal?.collection_name === 'cover' ? setTypeCoverDataUa : setTypeMobileDataUa
                    }
                    title='Редактирование картинки'
                />}
                {activeRemove && <ModalRemove
                    active={activeRemove}
                    setActive={setActiveRemove}
                    data={dataModal}
                    funcRemove={removeMedia}
                    setRemoveItem={dataModal?.custom_properties?.locale === 'ru'
                        ? dataModal?.collection_name === 'cover' ? setTypeCoverDataRu : setTypeMobileDataRu
                        : dataModal?.collection_name === 'cover' ? setTypeCoverDataUa : setTypeMobileDataUa
                    }
                    title='Удалить изображение'
                />}
            </Form>
        </>
    );
};

export default SpecOfferBannerEditView;
